import React from "react";
import baseData from "../../url/base.json";
import { domain } from "../../url/url";
import useThemeMgmt from "../../hooks/useThemeMgmt";
import useApiState from "../../store/store";

export const RenderComponent = ({ keyName, component: Component }) => {
  const val = useApiState((state) => state.webTableData);
  const show = keyName === undefined || val?.landing_page?.includes(keyName);
  if (show) {
    return <Component />;
  } else return null;
};

import React, { useEffect, useState } from "react";
import "./NewsB.scss";
import HeadingB from "../HeadingB/HeadingB";
import Newscard from "../UI/NewsCard/Newscard";
import { useNavigate } from "react-router-dom";
import useApiState from "../../store/store";
import { Notices } from "../Notices/Notices";

const NewsB = () => {
  const [factDetail] = useState();
  const [featuredStories, setFeaturedStories] = useState();
  const { allData } = useApiState();
  useEffect(() => {
    // setFeaturedStories(allData?.featuredStory?.data);
    setFeaturedStories(allData?.schoolWalls?.data);
  }, []);

  const factArray = factDetail?.[0]?.numeric_value?.map((item, index) => {
    return {
      number: item,
      type_of: factDetail?.[0]?.object_value[index].trim(),
      desc: factDetail?.[0]?.description[index].trim(),
    };
  });
  const navigate = useNavigate();

  return (
    <>
      <div className="NewsB_container " id="news_events">
        <div className="news_contents_container">
          <div className="grey_background_band"></div>
          <div className="container news_contents_wrapper">
            <div className="content_left">
              <div className="heading_section">
                <HeadingB
                  title="Latest News & Events"
                  subTitle="What's Happening New"
                />
              </div>
              <div className="news_section">
                <div className="row">
                  {featuredStories?.slice(0, 3)?.map((item, index) => {
                    // const class = index % 2 === 0 ? 'bbb' : 'aaa'
                    const value = index % 2 === 0 ? "fade-left" : "fade-right";

                    return (
                      <div className="col-md-4 " data-aos={value}>
                        <Newscard
                          date={item?.postedDate}
                          img={item?.post_image}
                          heading={item?.student_image}
                          details={item?.description}
                          key={index}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="">
              <div style={{ width: "fit-content", marginLeft: "auto" }}>
                <div
                  className="viewAll_btn"
                  onClick={() => {
                    navigate("/events");
                  }}
                >
                  view all
                </div>
              </div>

              <Notices data={allData?.upcomingEvents?.data?.slice(0, 5)} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsB;

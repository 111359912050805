import React from "react";
import "./FeatureSlider.scss";
import Slider from "react-slick";
import picOne from "../../images/one.jpg";
import picTwo from "../../images/two.jpg";
import picThree from "../../images/banner-c.png";
import useApiState from "../../store/store";

const FeatureSlider = () => {
  const { allData } = useApiState();

  const matchedItems = allData?.singlePageContent?.data?.filter((item) =>
    item?.keyword.startsWith("program_")
  );
  const settings = {
    slidesToShow: 3,
    swipeToSlide: true,
    infinite: true,
    focusOnSelect: true,
    arrows: false,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  if (matchedItems?.length > 0) {
    return null;
  } else
    return (
      <div className="featureSlider_container section-gap">
        <Slider {...settings}>
          {matchedItems?.map((item, index) => {
            return (
              <div key={index} className="feature_box_container">
                <div className="img_box">
                  <img src={item?.img} alt="img" />
                </div>
                <div className="content_box">
                  <h2 className="title">{item?.title}</h2>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    );
};

export default FeatureSlider;

import React, { useEffect, useState } from "react";
import "./GallaryC.scss";
import useApiState from "../../store/store";
import gallary from "../../images/gallary.png";

import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import GallaryView from "../GallaryB/GallaryView";
import HeadingB from "../HeadingB/HeadingB";
import { MetaConfigure } from "../helmet";

function GalleryC() {
  const { allData } = useApiState();
  const [activeTab, setActiveTab] = useState(0);
  const [allImages, setAllImages] = useState([]);

  useEffect(() => {
    if (allData && allData?.events && allData?.events?.events) {
      const images = allData?.events?.events.reduce((acc, curr) => {
        return acc.concat(curr.medias ? curr.medias : []);
      }, []);

      setAllImages(images);
    } else {
      console.error("Error fetching data:", allData);
    }
  }, [allData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const datas = allImages?.map((item) => {});
  const events = allData?.events?.events;
  const renderImages = (mediaList) => {
    const filterImages = mediaList?.filter((item) =>
      item?.startsWith("https://storage.googleapis.com")
    );

    const filtervideos = mediaList?.filter(
      (item) => !item?.startsWith("https://storage.googleapis.com")
    );

    return (
      <div className="images_container">
        <MetaConfigure title="Gallery" />

        {/* {Array.isArray(mediaList) &&
          mediaList.map((media, index) => {
            const isImage = /\.(png|jpg)$/i.test(media);
            const isVideo =
              !isImage && media?.startsWith("storage.googleapis") === false;
            return <GallaryView images={filterImages} videos={isVideo} />;
            // if (isImage) {
            //   return (
            //     <div className="images_wrapper" key={index}>
            //       <img src={media} alt={`image_${index}`} />
            //     </div>
            //   );
            // } else if (isVideo) {
            //   return (
            //     <div className="images_wrapper" key={index}>
            //       <iframe
            //         width="560"
            //         height="315"
            //         src="https://www.youtube.com/embed/tgbNymZ7vqY"
            //         frameborder="0"
            //         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            //         allowfullscreen></iframe>
            //     </div>
            //   );
            // } else {
            //   return (
            //     <div className="images_wrapper" key={index}>
            //       <p>This is not a supported media type</p>
            //     </div>
            //   );
            // }
          })} */}

        <GallaryView images={filterImages} videos={filtervideos} />
      </div>
    );
  };

  return (
    <>
      <div className="banner-image-area">
        <img src={gallary} alt="banner-img" />
        <div className="overlay"></div>
        <div className="sub-banner-image">
          <HeadingB color="#FFFFFF" title="Gallary" subTitle="Images" />
        </div>
      </div>
      <div className="container section-gap" style={{ marginTop: "30px" }}>
        <Tabs
          selectedIndex={activeTab}
          onSelect={(index) => setActiveTab(index)}
        >
          <TabList className="gallery-container">
            {events?.map((item, index) => (
              <Tab className="tabs" key={index}>
                {item?.event_name}
              </Tab>
            ))}
          </TabList>

          {/* <TabPanel>{activeTab === 0 && renderImages(allImages)}</TabPanel> */}
          {events?.map((item, index) => (
            <TabPanel key={index}>
              {activeTab === index && renderImages(item.medias)}
            </TabPanel>
          ))}
        </Tabs>
      </div>
    </>
  );
}

export default GalleryC;

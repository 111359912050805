import React, { useState, useEffect } from "react";
import "./TeamB.scss";
import HeadingB from "../HeadingB/HeadingB";
import TeamCard from "../UI/TeamCard/TeamCard";
import img1 from "../../images/teamB_1.png";
import img2 from "../../images/teamB_2.png";
import img3 from "../../images/teamB_3.png";
import useApiState from "../../store/store";
import AllTeam from "../AllTeam/AllTeam";
import { Link } from "react-router-dom";

const teamDetail = [
  { img: img1, name: "Ralph Edwards", designation: "Science Teacher" },
  { img: img2, name: "Darlene Robertson", designation: "Math Teacher" },
  { img: img3, name: "Albert Flores", designation: "Nepali Teacher" },
];

const TeamB = () => {
  const [members, setMembers] = useState();
  const { allData } = useApiState();

  useEffect(() => {
    setMembers(allData?.schoolMember?.data);
  }, []);
  return (
    <div className="teamB-section">
      <div className="container">
        <div className="section-gap">
          <div className="heading_wrapper">
            <HeadingB title="our team" subTitle="Our Experienced Instructors" />
            <Link to="/teams">
              <div className="viewAll_btn">view all</div>
            </Link>
          </div>
          {/* <div className="card_wrapper">
            <div className="row">
              {members?.map((item, index) => (
                <div className="col-md-4 col-md-4 col-sm-12">
                  <TeamCard
                    key={index}
                    image={item?.image_link}
                    name={item?.teacherName}
                    designation={item?.designation}
                  />
                </div>
              ))}
            </div>
          </div> */}
          <AllTeam isHomePage={true} />
        </div>
      </div>
    </div>
  );
};

export default TeamB;

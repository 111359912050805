import React, { useEffect, useState } from "react";
import { ArrowRight } from "tabler-icons-react";
import "./exploreB.scss";
import { useTimer } from "react-timer-hook";

const ExploreB = () => {
  const futureDate = new Date("2024-04-25T12:00:00"); // Set the specific date here

  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp: futureDate,
    autoStart: true,
    onExpire: () => console.warn("onExpire called"),
  });

  return (
    <div className="section-gap">
      <div className="explore-wrapper">
        <div>
          <div className="row">
            <div
              className="col-lg-9 col-sm-12"
              style={{ padding: "24px 80px" }}
            >
              <h3 className="title">A day in the life</h3>
              <p className="descB">
                Please join us as we walk through a day in the life of a Nivid
                School student.
              </p>
              <button className="apply-btn">
                Apply Now
                <ArrowRight />
              </button>
            </div>
            <div className="col-lg-3 col-sm-12">
              <div className="timer">
                <p>Admission to school starts in</p>
                <span className="sub_headingB">
                  {days} : {hours} : {minutes} : {seconds}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreB;

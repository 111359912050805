import React, { useEffect, useState } from "react";
import { MetaConfigure } from "../helmet";
import HeadingB from "../HeadingB/HeadingB";
import "./EventsPage.scss";
import useApiState from "../../store/store";
import Newscard from "../UI/NewsCard/Newscard";
import { Notices } from "../Notices/Notices";

export const EventsPage = () => {
  const { allData } = useApiState();
  const featuredStories = allData?.schoolWalls?.data;
  const noticeData = allData?.upcomingEvents?.data;
  const [selectedData, setSelectedData] = useState();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleSelect = (id) => {
    setSelectedData(featuredStories?.filter((item) => item.id === id));
  };

  return (
    <div className="events-full-page section-gap">
      <MetaConfigure title="Events" />
      <div className="banner-image-area">
        <img src={require("../../images/banner-c.png")} alt="banner-img" />
        <div className="overlay"></div>
        <div className="sub-banner-image">
          <HeadingB
            color="#fff"
            title="School Wall"
            subTitle="Latest News and Events"
          />
        </div>
      </div>
      <div className="container">
        <div className="row section-gap">
          <div className="col-md-9">
            <div className="row">
              {featuredStories?.map((item, index) => {
                const value = index % 2 === 0 ? "fade-left" : "fade-right";

                return (
                  <div className="col-lg-4 col-sm-6" data-aos={value}>
                    <Newscard
                      date={item?.postedDate}
                      img={item?.post_image}
                      heading={item?.student_image}
                      details={item?.description}
                      data_id={item?.id}
                      handleSelect={handleSelect}
                      selectedData={selectedData}
                    />
                  </div>
                );
              })}
            </div>
          </div>

          <div className="col-lg-3 col-md-12">
            <Notices data={noticeData} />
          </div>
        </div>
      </div>
    </div>
  );
};

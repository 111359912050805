import axios from "axios";
import { domain } from "../../url/url";

export const getGalleryPage = () => {
  return axios.get(`${domain}/all_events`);
};

export const getGallary = () => {
  return axios.get(`${domain}/gallery_images`);
};
export const getSchoolInfo = () => {
  return axios.get(`${domain}/get_school_info`);
};

export const getHomePageTopic = () => {
  return axios.get(`${domain}/get_homepage_topic`);
};

// export const getLevelInfo = () => {
//   return axios.get(`${domain}/get_level_info`);
// };

export const getSchoolMember = () => {
  return axios.get(`${domain}/school_members`);
};

// export const getFeaturedStories = () => {
//   return axios.get(`${domain}/get_featured_stories`);
// };

export const getAlumniReview = () => {
  return axios.get(`${domain}/get_alumni_review`);
};

// export const getSchoolFact = () => {
//   return axios.get(`${domain}/get_school_fact`);
// };

export const getWebpageText = () => {
  return axios.get(`${domain}/get_webpage_text`);
};

// export const getRedirectHeaders = () => {
//   return axios.get(`${domain}/get_redirect_headers`);
// };

export const getSchoolWalls = () => {
  return axios.get(`${domain}/school_wall_posts`);
};
// export const getAllApi = async () => {
//   try {
//     const response = await Promise.all([
//       getSchoolInfo(),
//       getHomePageTopic(),
//       getLevelInfo(),
//       getSchoolMember(),
//       getFeaturedStories(),
//       getAlumniReview(),
//       getSchoolFact(),
//       getWebpageText(),
//       getRedirectHeaders(),
//       getGallary(),
//     ]);
//   } catch (error) {
//     console.error(error);
//   }
// };

export const getSinglePageContent = () => {
  return axios.get(`${domain}/get_single_page_content`);
};

// export const getSchoolNotices = () => {
//   return axios.get(`${domain}/school_notices`);
// };

export const getBenefits = () => {
  return axios.get(`${domain}/benefits`);
};

export const getUpcomingEvents = () => {
  return axios.get(`${domain}/upcoming_events`);
};

export const getLayout= ()=>{
  return axios.get(`${domain}/web_tables`)
}

import React from "react";
import "./schoolInfo.scss";
import * as Tabler from "tabler-icons-react";

const SchoolInfo = () => {
  const schoolInfoSets = [
    {
      icon: "Globe",
      title: "93",
      subtitle: "Full-time faculty",
    },
    {
      icon: "Percentage",
      title: "70",
      subtitle: "Percentage of master degree",
    },
    {
      icon: "Users",
      title: "735",
      subtitle: "Current enrollment",
    },
    {
      icon: "School",
      title: "8:1",
      subtitle: "Teacher Student Ratio",
    },
  ];
  return (
    <div className="container section-gap">
      <div className="school-info-wrapper">
        <div className="row">
          {schoolInfoSets?.map((item, index) => {
            const IconName = `${item.icon
              .split("-")
              ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join("")}`;

            const Icon = Tabler[IconName];
            return (
              <div className="col-lg-3 col-md-6 col-sm-12 info-wrap">
                <div className="row info">
                  <div className="col-2">
                    {Icon && <Icon size={30} className="icon" />}
                  </div>
                  <div className="col-9 text">
                    <h2 className="sub_headingB">{item.title}</h2>
                    <p className="descB">{item.subtitle}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SchoolInfo;

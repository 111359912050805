import React from "react";
import useApiState from "../../store/store";
import { TbBuildingCommunity } from "react-icons/tb";
import { TbDeviceGamepad2 } from "react-icons/tb";
import { RiUserShared2Line } from "react-icons/ri";
import { FaGraduationCap } from "react-icons/fa";
import { LiaRunningSolid } from "react-icons/lia";
// import * as Tabler from "react-tabler-icons";
import * as Tabler from "tabler-icons-react";
function HelpSection() {
  const { allData } = useApiState();
  const matchedItems = allData?.singlePageContent?.data?.filter((item) =>
    item?.keyword.startsWith("facility_")
  );
  const iconList = [
    {
      icon: <TbBuildingCommunity color="white" />,
      keyword: "building-community",
    },
    {
      icon: <TbDeviceGamepad2 color="white" />,
      keyword: "device-gamepad-2",
    },
    { icon: <RiUserShared2Line color="white" />, keyword: "user-share" },
  ];
  return (
    <div div className="container section-gap">
      <h1 className="sub_headingB">How can we help you</h1>
      <div className="benifit_bottom_section">
        <div className="row">
          {matchedItems?.map((item, index) => {
            const IconName = `${item.icon
              .split("-")
              ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join("")}`;

            const Icon = Tabler[IconName];
            const defaultIcon = iconList.filter(
              (icon) => icon.keyword === item.icon
            );
            return (
              <div
                key={index}
                className="col-lg-3 col-md-6 col-xl-3 col-sm-12 card"
                data-aos="fade-right"
              >
                <div className="card_content_section">
                  <div className="card_img_section">
                    {Icon ? <Icon color="white" /> : defaultIcon[0]?.icon}
                  </div>
                  <div className="heading">{item?.title}</div>
                  <div className="desc" data-aos="fade-left">
                    {/* Using `dangerouslySetInnerHTML` to render HTML content */}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.content,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default HelpSection;

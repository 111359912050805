import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { Says, datas } from "../Data/Data";
import "./caurosel.scss";
import useApiState from "../../store/store";

function Carousel() {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const slider1 = useRef(null);
  const slider2 = useRef(null);
  const { allData } = useApiState();

  const testimonial = allData?.alumniReview?.data;
  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

  const settings1 = {
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    dots: false,
    afterChange: (index) => setActiveIndex(index),
  };

  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="carousel">
      <img className="comma" src="images/comma.png" alt="img" />
      <Slider
        {...settings1}
        asNavFor={nav2}
        ref={(slider) => (slider1.current = slider)}
        className="carousel-slider">
        {testimonial?.map((item, index) => (
          <div key={index}>
            <div className="testinomial-title">
              <div dangerouslySetInnerHTML={{ __html: item.review }}></div>
            </div>
          </div>
        ))}
      </Slider>
      <div className="base">
        <Slider
          {...settings2}
          asNavFor={nav1}
          ref={(slider) => (slider2.current = slider)}>
          {testimonial?.map((data, index) => (
            <div key={index}>
              <div
                className={`first-profile ${
                  index === activeIndex ? "active" : ""
                }`}>
                <div className="profile-content">
                  <div className="img-box">
                    <img
                      data-aos="zoom-in"
                      data-aos-once="true"
                      className="images"
                      src={data.image_link}
                      alt="img"
                    />
                  </div>

                  <div
                    className="content-box"
                    data-aos="zoom-in"
                    data-aos-once="true">
                    {/* <h2>{index + 1}</h2> */}
                    <h5 className="sub-title">{data.studentName}</h5>
                    <p className="content">{data.gradeName}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Carousel;

import React from "react";
import HeadingB from "../HeadingB/HeadingB";
import "./admissionOpen.scss";

const AdmissionDate = () => {
  return (
    <div className="container admission-date-wrapper">
      <div className="text-wrapper">
        <HeadingB
          title="Admission Dates"
          subTitle="Application Dates When to Apply 2081?"
          color="white"
          icon
        />
      </div>
      <div className="down-triangle"></div>
    </div>
  );
};

export default AdmissionDate;

import React from "react";
import "./Notices.scss";

export const Notices = ({ data }) => {
  return (
    <div className="notice-section">
      <div className="content_right">
        <div className="title_section">
          <h3 className="heading">Upcoming Events</h3>
        </div>

        {data?.map((item) => {
          return (
            <div className="detail_section">
              <div className="date-section">
                <h5>{item?.month} </h5>
                <p>{item?.day} </p>
              </div>

              <div className="event-title">
                <p>{item?.description}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

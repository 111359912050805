import React from "react";
import "./TeamCard.scss";
import userImg from "../../../images/userimg.webp";

const TeamCard = ({ image, name, designation }) => {
  return (
    <div className="team_card_container">
      <div className="image-wrapper">
        {image === "https://storage.googleapis.com/nivid/" ? (
          <img src={userImg} alt="team" />
        ) : (
          <img src={image || userImg} alt="team" />
        )}
      </div>
      <div className="team_info_wrapper">
        <div className="name_wrapper">{name}</div>

        <div className="desgination_wrapper">{designation}</div>
      </div>
    </div>
  );
};

export default TeamCard;

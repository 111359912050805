import React, { useEffect, useState } from "react";
import "./GetStarted.scss";
import { FaFacebookF } from "react-icons/fa";
import { CiLinkedin } from "react-icons/ci";
import { TiSocialYoutube } from "react-icons/ti";
import { BsTelephone } from "react-icons/bs";
import { IoMailOutline } from "react-icons/io5";
import { GrLocation } from "react-icons/gr";
import useApiState from "../../store/store";
import { FaArrowRight } from "react-icons/fa";
const socialLogo = [
  { logo: <FaFacebookF />, link: "https://www.facebook.com" },
  { logo: <CiLinkedin />, link: "https://www.linkedin.com" },
  { logo: <TiSocialYoutube />, link: "https://www.youtube.com" },
];
const datas = [
  { name: "Terms of Use" },
  { name: "Privacy Policy" },
  { name: "Data Security" },
  { name: "Cookie Settings" },
];
function GetStarted() {
  const { allData } = useApiState();
  const [schoolInfo, setSchoolInfo] = useState();
  const [webText, setWebText] = useState();
  const currentYear = new Date().getFullYear();
  useEffect(() => {
    setWebText(allData?.webPageText?.data?.[0]);
    setSchoolInfo(allData?.schoolInfo?.data?.[0]);
  }, []);
  return (
    <>
      <div className="container">
        <div className="start-container">
          <div className="get-started">
            <h2 className="sub_headingB">Ready to get started?</h2>
            <p className="descB">
              Welcome to ABM We're excited to embark on this educational journey
              with you. Whether you're a prospective student, parent, or
              educator, we're here to guide you every step of the way.
            </p>
            <div className="social-media">
              {socialLogo?.map((social, index) => (
                <a key={index} target="_blank" href={social?.link}>
                  <div className="social_box">{social?.logo}</div>
                </a>
              ))}
            </div>
          </div>

          <div className="col-lg-3 col-md-3 col-sm-12 " data-aos="fade-right">
            <div className="list-content ">
              <div className="footer_title">Pages</div>
              <ul>
                <li>
                  <a className="footer_list" href="/school">
                    <div>Academics</div>
                  </a>
                </li>
                <li>
                  <a className="footer_list" href="/admission_form">
                    <div>Admission Form</div>
                  </a>
                </li>
                <li>
                  <a className="footer_list" href="/events">
                    <div>News & Events</div>
                  </a>
                </li>
                <li>
                  <a className="footer_list" href="/gallery">
                    <div>Gallery</div>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-md-3 col-sm-12" data-aos="fade-left">
            <div className="contact_us_section ">
              <div className="footer_title">Contact Us</div>
              <div className="contact_list_wrapper">
                <BsTelephone size={18} />
                <div className="contact_list_title">{schoolInfo?.contact}</div>
              </div>
              <div className="contact_list_wrapper">
                <IoMailOutline size={18} />

                <div className="contact_list_title">{schoolInfo?.email}</div>
              </div>
              <div className="contact_list_wrapper">
                <GrLocation size={24} />
                <div className="contact_list_title">{schoolInfo?.address}</div>
              </div>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="row  below-footer">
          <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-right">
            <div className="image_wrapper">
              <img src={schoolInfo?.image_link} alt="#" />
              <div className="data_collection">
                {datas?.map((data, index) => (
                  <p key={index} className="descB datas">
                    {data.name}
                  </p>
                ))}
              </div>
              <p className="descB copyright">
                Copyrights © {currentYear} {schoolInfo?.school_name} | All
                Rights Reserved.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-5 col-sm-12 " data-aos="fade-right">
            <div className="news-letter">
              <h3 className="title">Newsletter</h3>
              <p className="descB">
                By clicking “Subscribe”, you agree to our
                <span> Privacy Policy</span>
              </p>
              <div className="enter-email">
                <input
                  className="input_field"
                  placeholder="Enter your email Address"
                ></input>
                <button className="suscribe">
                  <p>Suscribe</p>
                  <FaArrowRight style={{ marginTop: "5px", color: "white" }} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GetStarted;

import create from "zustand";
import {
  getAlumniReview,
  getGalleryPage,
  getSchoolInfo,
  getSchoolMember,
  getSchoolWalls,
  getSinglePageContent,
  getUpcomingEvents,
  getWebpageText,
} from "../components/Api/getApi";
import { devtools } from "zustand/middleware";

export const useApiState = create(
  devtools((set) => ({
    loader: true,
    isError: null,
    data: null,
    allData: {},
    isTeamSlice:false,
    webTableData:null,
    setWebTableData: (val)=> set({webTableData:val}),
    getApi: async () => {
      set({ loader: true });

      try {
        const responses = await Promise.all([
          getSchoolInfo(),
          getSchoolMember(),
          getAlumniReview(),
          getWebpageText(),
          getSchoolWalls(),
          getSinglePageContent(),
          getGalleryPage(),
          getUpcomingEvents(),
        ]);
        set({ loader: false, data: responses });
        set({
          allData: {
            schoolInfo: responses?.[0]?.data,
            schoolMember: responses?.[1]?.data,
            alumniReview: responses?.[2]?.data,
            webPageText: responses?.[3]?.data,
            schoolWalls: responses?.[4]?.data,
            singlePageContent: responses?.[5]?.data,
            events: responses?.[6]?.data,
            upcomingEvents: responses?.[7]?.data,
          },
        });
      } catch (error) {
        set({ loader: false, isError: error });
      }
    },
  }))
);

export default useApiState;

import React, { useEffect, useState } from "react";
import { ImageContent } from "../ImageContent/ImageContent";
import useApiState from "../../store/store";
import HeadingB from "../HeadingB/HeadingB";

export const PrincipalMessage = () => {
  const [pageDetail, setPageDetails] = useState();
  const { allData } = useApiState();

  useEffect(() => {
    const responseData = allData.singlePageContent.data;
    const pageData = responseData?.filter(
      (item) => item.keyword === "principal_statement"
    );

    setPageDetails(pageData?.[0]);
  }, []);
  return (
    <div className="principal-message-section section-gap">
      <div className="container">
        <HeadingB title="" subTitle="Message from our principal" />
      </div>

      <ImageContent data={pageDetail} textMin={true} />
    </div>
  );
};

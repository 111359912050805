import React, { useEffect, useState } from "react";

import { MdOutlineArrowRightAlt } from "react-icons/md";
import "./BannerEnrollment.scss";
import useApiState from "../../../store/store";
import { filterKeyword } from "../../../utils/filterKeywords";
import { useNavigate } from "react-router-dom";

const BannerEnrollMent = () => {
  const { allData } = useApiState();
  const matchItem = filterKeyword(allData?.singlePageContent?.data, "header");
  const [currentNepaliYear, setCurrentNepaliYear] = useState(null);

  useEffect(() => {
    function get_current_nepali_year() {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;

      let nepaliYear;

      if (currentMonth > 4) {
        nepaliYear = currentYear + 57;
      } else {
        nepaliYear = currentYear + 58;
      }

      return `${nepaliYear} - ${nepaliYear + 1}`;
    }

    const nepaliYear = get_current_nepali_year();
    setCurrentNepaliYear(nepaliYear);
  }, []);
  const navigate = useNavigate();
  return (
    <div className="HeroBannerA_container">
      <div className="left_content">
        <img src={matchItem?.[0]?.title_image_link} alt="hero_banner" />
        <div className="overlay"></div>
        <div className="main_moto">
          <h1>
            {matchItem?.[0]?.title}
            <br />
            <span>{matchItem?.[0]?.subtitle}</span>
          </h1>
        </div>
      </div>

      <div className="right_content">
        <img src={matchItem?.[0]?.image_link} alt="bag" />

        <div className="enrollment">
          <h2>Enrollment for {currentNepaliYear} open</h2>
          <a href="#">
            <span
              onClick={() => {
                navigate("/admission_document");
              }}>
              Learn More
            </span>{" "}
            <MdOutlineArrowRightAlt />
          </a>
        </div>
      </div>
    </div>
  );
};

export default BannerEnrollMent;

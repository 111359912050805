


import React, { useState, useEffect } from "react";
import "./WhatSetsUsParallax.scss";
import signature from "../../images/signature.png";
import useApiState from "../../store/store";

const WhatSetsUsParallax = () => {
  const [webText, setWebText] = useState();
  const { allData } = useApiState();

  const about_us = webText?.header_about_us;

  useEffect(() => {
    setWebText(allData?.webPageText?.data?.[0]);
  }, []);
  const backgroundImageUrl = require('../../images/what.jpg');
  return (
    <section style={{backgroundImage:`url(${backgroundImageUrl})`}} className="what_set_us_parallax" >
        <div  className="container">
      <div className="what_sets_us_container" data-aos="fade-down">
        <h2>What sets us apart</h2>
        <div className="desc_wrapper">
          <div dangerouslySetInnerHTML={{ __html: about_us }}></div>
          {/* <img src={webText?.signature_link} alt="signature" /> */}
        </div>
      </div>
      </div>
    </section>
  );
};

export default WhatSetsUsParallax;

import React from "react";
import "./OurProgramBParallax.scss";
import { filterKeyword } from "../../utils/filterKeywords";
import useApiState from "../../store/store";
import HeadingB from "../HeadingB/HeadingB";

const OurProgramBParallax = () => {
  const { allData } = useApiState();

  const programList = ["montessori", "plus_2", "school", "programs"];
  const matchedItems = filterKeyword(
    allData.singlePageContent?.data,
    programList
  );
  const backgroundImageUrl = require("../../images/what.jpg");

  return (
    <section
      style={{ backgroundImage: `url(${backgroundImageUrl})` }}
      className="OurProgramBParallax_container"
      id="academics">
      <div className="container">
        <div className="OurProgramB_wrapper">
          <div className="row">
            <div className="col-md-12 col-xl-8 col-sm-12 order-smm-2">
              <div className="program_box_wrapper ">
                <div className="row">
                  {matchedItems?.map((item, index) => (
                    <div className="col-md-3 main-content">
                      <div
                        key={index}
                        className="program_box"
                        data-aos="fade-right">
                        <div className="title">{item?.title}</div>
                        <div className="descB desc">{item?.subtitle}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-md-12 col-xl-4 col-sm-12 order-smm-1">
              {/* <div className="program_title_wrapper" data-aos="fade-left">

                <div className="headingB">
                  <div className="line"></div>
                  <div className="title">our programs</div>
                </div>
                <h2>Discover our top-notch programs we are home for pupils</h2>
              </div> */}
              <HeadingB
                color="#FFFFFF"
                title="OUR PROGRAMS"
                subTitle="Discover our top-notch programs we are home for pupils"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurProgramBParallax;

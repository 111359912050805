import React, { useEffect, useState } from "react";
import { IoIosCall } from "react-icons/io";
import "./TopHeader.scss";
import { MdOutlineMailOutline } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { FaFacebookF } from "react-icons/fa";
import { RiLinkedinBoxLine } from "react-icons/ri";
import { FaYoutube } from "react-icons/fa6";
import useApiState from "../../store/store";
export const TopHeader = () => {
  const { allData } = useApiState();
  const [schoolInfo, setSchoolInfo] = useState();

  useEffect(() => {
    setSchoolInfo(allData?.schoolInfo?.data?.[0]);
  }, []);
  return (
    <div className="top-header-section">
      <div className="container">
        <div className="header-wrap-block">
          <div className="web-info">
            <div className="content">
              <IoIosCall />
              <p>{schoolInfo?.contact}</p>
            </div>
            <div className="content hr-line">
              <MdOutlineMailOutline />
              <p>{schoolInfo?.email}</p>
            </div>{" "}
            <div className="content hr-line">
              <IoLocationOutline />
              <p>{schoolInfo?.address}</p>
            </div>
          </div>

          <div className="social-info">
            <a target="_blank" href="https://facebook.com">
              <FaFacebookF />
            </a>

            <a target="_blank" href="https://www.linkedin.com/">
              <RiLinkedinBoxLine />
            </a>
            <a target="_blank" href="https://www.youtube.com/">
              <FaYoutube />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

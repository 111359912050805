import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "./App.css";
import { RenderComponent } from "./components/RenderComponent";
import { subdomain, domain } from "./url/url";
import useThemeMgmt from "./hooks/useThemeMgmt";
import ContactUsB from "./components/ContactUsB/ContactUsB";
import FooterB from "./components/FooterB/FooterB";
import HeaderB from "./components/Header/HeaderB/HeaderB";
import { useApiState } from "./store/store";
import AllHeaders from "./components/AllHeaders/AllHeaders";
import GetStarted from "./components/GetStarted/GetStarted";
import NewsB from "./components/NewsB/NewsB";
import HomePopup from "./components/HomePopup/HomePopup";
import SlideToUp from "./components/SlideToUp/SlideToUp";
import { TopHeader } from "./components/TopHeader/TopHeader";

function App() {
  const [isOpen, setIsOpen] = useState(true);
  const url = `${domain}/web_tables`;

  const { val } = useThemeMgmt(url);

  const { loader, isError, getApi, setWebTableData } = useApiState();

  useEffect(() => {
    setWebTableData(val);
  }, [val]);

  useEffect(() => {
    AOS.init();
    getApi();
  }, []);

  const handlePopUp = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const root = document.documentElement;
    if (val !== undefined && val !== null) {
      root?.style.setProperty(
        "--primary-color",
        val?.variable["primary-color"]
      );
      root?.style.setProperty(
        "--secondary-color",
        val?.variable["secondary-color"]
      );
      root?.style.setProperty(
        "--tertiary-color",
        val?.variable["tertiary-color"]
      );
      root?.style.setProperty(
        "--quaternary-color",
        val?.variable["quaternary-color"]
      );
      root?.style.setProperty("--primary-10", val?.variable["primary-10"]);
      root?.style.setProperty("--secondary-10", val?.variable["secondary-10"]);
    }
  }, [val]);
  const location = useLocation();
  if (loader) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="App">
      <ToastContainer />
      <SlideToUp />
      {isOpen && location.pathname === "/" && (
        <HomePopup onClose={handlePopUp} />
      )}

      <RenderComponent component={TopHeader} keyName="top-header" />
      <RenderComponent component={HeaderB} keyName="header-B" />
      <RenderComponent component={AllHeaders} keyName="all-headers" />
      <Outlet />
      <RenderComponent component={ContactUsB} keyName="contact_us_B" />
      <RenderComponent component={FooterB} keyName="footer_B" />
      <RenderComponent component={GetStarted} keyName="get-started-b" />
    </div>
  );
}

export default App;

import React from "react";
import "./SchoolLevel.scss";

import useApiState from "../../store/store";
import { filterKeyword } from "../../utils/filterKeywords";

function SchoolLevel() {
  const { allData } = useApiState();
  const items = ["montessori", "school", "plus_2"];
  const matchedItems = filterKeyword(allData?.singlePageContent?.data, items);

  return (
    <>
      <div className="school-level">
        {matchedItems?.map((data, index) => (
          <div className=" school-level-container ">
            <img src={data.title_image_link} />
            <div className="overlay"></div>
            <p className="text school"> {data.title}</p>
          </div>
        ))}
      </div>
    </>
  );
}

export default SchoolLevel;

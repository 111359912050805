import React, { useEffect, useState } from "react";
import "./GallaryB.scss";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Download from "yet-another-react-lightbox/plugins/download";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import HeadingB from "../HeadingB/HeadingB";
import ReactPlayer from "react-player";
import Gallery from "react-photo-gallery";
import { useNavigate } from "react-router-dom";

const GallaryView = ({ images, videos, isGap }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  let navigate = useNavigate();
  const openLightbox = (index) => {
    setLightboxIndex(index);
    setLightboxOpen(true);
  };

  const photos = images?.map((image, index) => {
    return {
      src: image,
      width: 4,
      height: 3,
    };
  });

  const [shuffledArray, setShuffledArray] = useState([]);

  const originalObject = {
    image: images,
    video: videos,
  };

  useEffect(() => {
    const convertedArray = Object?.entries(originalObject)?.flatMap(
      ([type, ids]) => ids?.map((id) => ({ id, type }))
    );
    const shuffled = [...convertedArray];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    setShuffledArray(shuffled);
  }, []);

  const mergedArray = shuffledArray
    ?.map((item, index) => {
      if (item?.type === "image") {
        return {
          src: item.id,
          width: 4,
          height: 3,
        };
      } else if (item?.type === "video" && item.id && item.id !== "{}") {
        return {
          src: item.id,
          type: "video",
          width: 16,
          height: 9,
        };
      }
      return null;
    })
    .filter((item) => item !== null);

  const photosForCarousel = mergedArray?.map((image, index) => {
    if (image.type === "video") {
      return {
        ...image,
        width: 16,
        height: 9,
      };
    } else {
      return {
        ...image,
        width: 3500,
        height: 540,
      };
    }
  });

  function cutArrayToDivisibleBy3(array) {
    if (array.length <= 3) {
      return array;
    }

    const remainder = array.length % 3;

    if (remainder === 0) {
      return array;
    }

    return array.slice(0, array.length - remainder);
  }

  function cutArrayToDivisibleBy4(array) {
    if (array.length <= 4) {
      return array;
    }

    const remainder = array.length % 4;

    if (remainder === 0) {
      return array;
    }

    return array.slice(0, array.length - remainder);
  }

  const noGapMediaItems = cutArrayToDivisibleBy4(mergedArray);

  const mediaItems = cutArrayToDivisibleBy3(mergedArray);

  return (
    <div className="gallery_container section-gap" id="gallery">
      {lightboxOpen && (
        <Lightbox
          open={lightboxOpen}
          slides={photosForCarousel}
          index={lightboxIndex}
          close={() => setLightboxOpen(false)}
          plugins={[Download, Thumbnails]}
        />
      )}
      <div className="container">
        <div className="heading_wrapper">
          <div className="row">
            <div className="col-md-10">
              <HeadingB
                title="gallery"
                subTitle="Explore Our School's Vibrant World"
              />
            </div>

            <div className="col-md-2">
              <div
                className="viewAll_btn"
                onClick={() => {
                  navigate("/gallery");
                }}
              >
                view all
              </div>
            </div>
          </div>
        </div>
        <div className="image-wrapper" data-aos="fade-right">
          <div className="row">
            {mediaItems?.length > 0 && (
              <Gallery
                photos={isGap ? noGapMediaItems : mediaItems}
                onClick={openLightbox}
                renderImage={({ photo, index }) =>
                  photo?.type === "video" ? (
                    <div
                      className={`video-container ${
                        isGap
                          ? "col-lg-3 col-md-6 col-sm-12"
                          : "col-lg-4 col-md-6 col-sm-12"
                      }`}
                    >
                      <ReactPlayer
                        url={photo.src}
                        width={isGap ? "100%" : "99%"}
                        height="100%"
                        style={{
                          margin: isGap ? "none" : "0px 10px 10px 0px",
                          borderRadius: isGap ? "none" : "10px",
                        }}
                        controls
                      />
                    </div>
                  ) : (
                    <div
                      className={`image-gallery ${
                        isGap
                          ? "col-lg-3 col-md-6 col-sm-12"
                          : "col-lg-4 col-md-6 col-sm-12"
                      }`}
                    >
                      <img
                        src={photo.src}
                        alt=""
                        width={isGap ? "100%" : "99%"}
                        height="300px"
                        style={{
                          margin: isGap ? "none" : "0px 10px 10px 0px",
                          borderRadius: isGap ? "none" : "10px",
                        }}
                        onClick={() => openLightbox(index)}
                      />
                    </div>
                  )
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GallaryView;

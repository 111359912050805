import React, { useState, useEffect } from "react";
import "./AboutUsB.scss";
import image from "../../images/aboutUsB.png";
import { MdOutlineArrowRight } from "react-icons/md";
import { GoBook } from "react-icons/go";
import { CiClock2 } from "react-icons/ci";
import { IoIosPeople } from "react-icons/io";
import { CiGlobe } from "react-icons/ci";
import { RiNewspaperLine } from "react-icons/ri";
import { LuPointer } from "react-icons/lu";
import useApiState from "../../store/store";
import { filterKeyword } from "../../utils/filterKeywords";
import { useNavigate } from "react-router-dom";

const AboutUsB = () => {
  const [webText, setWebText] = useState();
  const { allData } = useApiState();
  const [redirectHeaders, setRedirectHeaders] = useState();
  const [aboutusList, setAboutUsList] = useState();

  useEffect(() => {
    setWebText(allData?.webPageText?.data?.[0]);
    setRedirectHeaders(allData?.redirectHeaders?.data);
    setAboutUsList(allData?.singlePageContent?.data);
  }, []);

  const aboutUs = filterKeyword(allData?.singlePageContent?.data, "body");

  const aboutList = [
    { icon: <GoBook color="#29ABE2" />, keyword: "admission_document" },
    { icon: <CiClock2 color="#29ABE2" />, keyword: "working_hours" },
    { icon: <IoIosPeople color="#29ABE2" />, keyword: "community" },
    { icon: <CiGlobe color="#29ABE2" />, keyword: "patrons" },
    { icon: <RiNewspaperLine color="#29ABE2" />, keyword: "whats_happening" },
    { icon: <LuPointer color="#29ABE2" />, keyword: "apply_online" },
  ];
  const showList = [
    "admission_document",
    "working_hours",
    "community",
    "patrons",
    "whats_happening",
    "apply_online",
    "family_access",
  ];
  const matchedheaders = filterKeyword(
    allData.singlePageContent?.data,
    showList
  );
  const icon = matchedheaders?.[0]?.icon;

  //   const featureList = [
  //     { color: "#29ABE2", desc: "International reputation" },
  //     { color: "#6360AF", desc: "Prepares future leaders" },
  //     { color: "#339E71", desc: "Moral and spiritual compass" },
  //     { color: "#EDC729", desc: "Curriculum and instruction" },
  //   ];

  let navigate = useNavigate();

  return (
    <section className="container" id="about">
      <div className="aboutusB_container">
        <div className="row">
          <div className="col-md-12 col-xl-4">
            <div className="left_section" data-aos="fade-left">
              <div className="headingB">
                <div className="line"></div>
                <div className="title">about us</div>
              </div>
              <h2 className="sub_headingB">School values and excellence</h2>

              <div
                className="ten-lines"
                dangerouslySetInnerHTML={{
                  __html: aboutUs?.[0]?.content,
                }}
              ></div>
              {/* <p style={{ marginBottom: "24px" }} className="descB">
            Get ahead of the competition and discover trends before become
            yesterday’s news.
          </p>

          <div className="feature_list">
            {featureList?.map((item, index) => (
              <div key={index} className="bullets_container">
                <div
                  style={{ background: item?.color }}
                  className="bullets"
                ></div>
                <div className="descB">{item?.desc}</div>
              </div>
            ))}
          </div> */}
            </div>
          </div>

          <div className="col-md-12 col-xl-4">
            <div className="middle_section" data-aos="fade-top">
              <div className="img_container">
                <img src={aboutUs?.[0]?.image_link} alt="img" />
                <div className="img_desc">
                  <div className="title">{aboutUs?.[0]?.title}</div>
                  <div className="desc">{aboutUs?.[0]?.subtitle}</div>
                  <a href="#">Learn More</a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 col-xl-4">
            <div className="right_section" data-aos="fade-right">
              {matchedheaders?.map((item, index) => {
                const filtered = aboutList.filter(
                  (aboutItem) => item.keyword === aboutItem.keyword
                );
                const icon = filtered.length > 0 ? filtered[0].icon : null;

                return (
                  <div key={index} className="information_list_wrapper">
                    <div
                      className="icon_content_wrapper"
                      onClick={() => {
                        navigate(`/${item.keyword}`);
                      }}
                    >
                      {icon}
                      <span>{item?.subtitle}</span>
                    </div>
                    <MdOutlineArrowRight />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsB;

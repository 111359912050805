const { useState, useEffect } = require("react");

function useThemeMgmt(url) {
  const [val, setVal] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  function getStaticFile() {
    setLoading(true);
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Work with JSON data here

        setVal(data?.data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(setLoading(false));
  }

  useEffect(() => {
    if (url !== undefined && url !== null) {
      getStaticFile();
    }
  }, [url]);

  return { getStaticFile, val, loading, error };
}

export default useThemeMgmt;

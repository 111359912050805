import React from "react";
import AlumniCarouselWrapper from "./AlumniCarouselWrapper";
import AlumniCarousel from "./AlumniCarousel";
import AlumniCarouselB from "./AlumniCarouselB";

const Alumni1 = () => {
  return (
    <AlumniCarouselWrapper showTitle primaryColor>
      <AlumniCarousel />
    </AlumniCarouselWrapper>
  );
};

const Alumni2 = () => {
  return (
    <AlumniCarouselWrapper borderBottom>
      <AlumniCarouselB />
    </AlumniCarouselWrapper>
  );
};

export { Alumni1, Alumni2 };

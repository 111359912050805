import React, { useState, useEffect } from "react";
import { HiOutlineArrowUp } from "react-icons/hi";
import "./SlideToUp.scss";

function SlideToUp() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="arrow">
      {isVisible && (
        <button
          onClick={scrollToTop}
          className="scroll-top scroll-to-target open">
          <HiOutlineArrowUp />
        </button>
      )}
    </div>
  );
}

export default SlideToUp;

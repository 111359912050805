import React from "react";

import * as Tabler from "tabler-icons-react";

const CurriculumText = ({ title, subtitle, icon }) => {
  const Icon = Tabler[icon];
  return (
    <div className="col-lg-6 col-md-12 col-sm-12">
      <div className="row">
        <div className="col-2" style={{ marginRight: "6px" }}>
          {Icon && <Icon className="program_icon" size={40} />}
        </div>
        <div className="col-9">
          <h2 className="titleB">{title}</h2>
          <p className="descB">{subtitle}</p>
        </div>
      </div>
    </div>
  );
};

export default CurriculumText;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import reportWebVitals from "./reportWebVitals";
import "./styles/main.scss";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LandingPage from "./pages/LandingPage/LandingPage";

import App from "./App";
import { StudentAdmissionForm } from "./components/StudentAdmissionForm/StudentAdmissionForm";
import { InnerPage } from "./components/InnerPage/InnerPage";
import { ImageContent } from "./components/ImageContent/ImageContent";
import { BannerContent } from "./components/BannerContent/BannerContent";
import TeamCard from "./components/UI/TeamCard/TeamCard";
import AllTeam from "./components/AllTeam/AllTeam";
import AllTeamWrapper from "./components/AllTeam/AllTeamWrapper";

import GalleryC from "./components/GallaryC/GallaryC";
import { EventsPage } from "./components/EventsPage/EventsPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <LandingPage />,
      },
      {
        path: "/admission_form",
        element: <StudentAdmissionForm />,
      },
      {
        path: "/:route",
        element: <BannerContent />,
      },
      {
        path: "/gallery",
        element: <GalleryC />,
      },
      {
        path: "/teams",
        element: <AllTeamWrapper />,
      },
      {
        path: "/events",
        element: <EventsPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

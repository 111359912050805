import React, { useEffect, useState } from "react";
import useApiState from "../../store/store";
import "./BannerBottom.scss";

export const BannerBottom = () => {
  const { allData } = useApiState();

  return (
    <div className="bottom_section">
      <div className="feature_box_wrapper">
        <a href="/school_life">
          <div className="feature_box">
            <p>School Life</p>
            <span>More</span>
          </div>
        </a>
        <a href="#news_events">
          <div className="feature_box">
            <p>News & Events</p>
            <span>More</span>
          </div>
        </a>
        <a href="/admission_form">
          <div className="feature_box">
            <p>Admission</p>
            <span>More</span>
          </div>
        </a>
      </div>
      <div className="day_section">
        <p>
          A day in the life.&nbsp;
          <span>
            Please join us as we walk through a day in the life of a{" "}
            {allData?.schoolInfo?.data?.[0]?.school_name} student.
          </span>
        </p>
        <a href="/admission_document">learn more</a>
      </div>
    </div>
  );
};

import React from "react";
import "./ContactUs.scss";
import HeadingB from "../HeadingB/HeadingB";
import { BsTelephone } from "react-icons/bs";
import { CiMail } from "react-icons/ci";
import { IoLocationOutline } from "react-icons/io5";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { postAdmissionInquiry } from "../Api/postApi";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import useApiState from "../../store/store";
import { toast } from "react-toastify";

const ContactUsB = () => {
  const { allData } = useApiState();

  const contactDetails = allData?.schoolInfo?.data;

  const validate = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .min(3, "Name must be at least 3 characters"),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email address"),
    phoneNo: Yup.string()
      .required("Phone Number is required")
      .matches(/^\d{8,10}$/, "Phone Number must be between 8 to 10 digits"),
    message: Yup.string().required("Message is required"),
  });

  return (
    <div className="contactUsB_container" id="contact">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="detail_section section-gap">
              <div className="heading_wrapper">
                <HeadingB
                  color="#FFFFFF"
                  title="contact us"
                  subTitle="Admission Inquiry"
                />
                <p style={{ color: "#DDDDDD" }} className="descB">
                  Message us and we will reply you as soon as possible Please
                  submit your information below and we will be in touch shortly.
                </p>
              </div>
              <div className="detail_card">
                <BsTelephone size={38} />

                <div className="detail_desc_wrapper">
                  <div className="title">Phone Number:</div>
                  <div>{contactDetails?.[0]?.contact}</div>
                </div>
              </div>
              <div className="detail_card">
                <CiMail size={38} />

                <div className="detail_desc_wrapper">
                  <div className="title">Email Address:</div>
                  <div>{contactDetails?.[0]?.email}</div>
                </div>
              </div>
              <div className="detail_card">
                <IoLocationOutline size={38} />

                <div className="detail_desc_wrapper">
                  <div className="title">Address:</div>
                  <div>{contactDetails?.[0]?.address}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="form_section section-gap">
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  phoneNo: "",
                  message: "",
                }}
                validationSchema={validate}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  postAdmissionInquiry(values)
                    .then(() => {
                      toast.success("Contact Form submit sucessfully");
                    })
                    .catch((error) => {})
                    .finally(() => {
                      setSubmitting(false);
                      resetForm();
                    });
                }}>
                {({ isSubmitting }) => (
                  <Form>
                    <div className="field-wrap">
                      <Field name="name">
                        {({ field, form }) => (
                          <TextField
                            {...field}
                            className="input white-outline"
                            id="outlined-basic"
                            label="Name*"
                            variant="outlined"
                            InputLabelProps={{
                              className: "label-color",
                            }}
                            inputProps={{
                              style: {
                                borderColor: "white",
                              },
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="field-wrap">
                      <Field name="email">
                        {({ field, form }) => (
                          <TextField
                            {...field}
                            className="input white-outline"
                            label="Email*"
                            variant="outlined"
                            InputLabelProps={{
                              className: "label-color",
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="field-wrap">
                      <Field name="phoneNo">
                        {({ field, form }) => (
                          <TextField
                            {...field}
                            className="input white-outline"
                            label="Phone Number*"
                            InputLabelProps={{
                              className: "label-color",
                            }}
                            variant="outlined"
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="phoneNo"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="field-wrap">
                      <Field name="message">
                        {({ field, form }) => (
                          <TextField
                            {...field}
                            textarea={true}
                            className="input white-outline"
                            label="Message*"
                            variant="outlined"
                            InputLabelProps={{
                              className: "label-color",
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="message"
                        component="div"
                        className="error"
                      />
                    </div>

                    <button
                      type="submit"
                      style={{ textTransform: "uppercase" }}>
                      Contact
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsB;

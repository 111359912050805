import React, { useState, useEffect } from "react";
import "./FooterB.scss";
import logo from "../../images/logo.svg";
import { FaFacebookSquare, FaLinkedin, FaYoutube } from "react-icons/fa";
import { BsTelephone } from "react-icons/bs";
import { IoMailOutline } from "react-icons/io5";
import { CiLocationOn } from "react-icons/ci";
import useApiState from "../../store/store";
import { filterKeyword } from "../../utils/filterKeywords";
import { useNavigate } from "react-router-dom";

const socialLogo = [
  { logo: <FaFacebookSquare />, link: "https://www.facebook.com" },
  { logo: <FaLinkedin />, link: "https://www.linkedin.com" },
  { logo: <FaYoutube />, link: "https://www.youtube.com" },
];

// const pages = [
//   { title: "About ", route: "about" },
//   { title: "Academics ", route: "academics" },
//   { title: "Admissions", route: "contact" },
//   { title: "News & Events", route: "news_events" },
//   { title: "Gallery", route: "gallery" },
// ];
const pages = ["about,academics,admissions"];
const help = [
  "help_and_support",
  "faq",
  "School Policy",
  "Guidance and Counsellor",
];
const footer = ["footer"];

const FooterB = () => {
  const { allData } = useApiState();
  const [schoolInfo, setSchoolInfo] = useState();
  const currentYear = new Date().getFullYear();
  const [more, setMore] = useState({});
  useEffect(() => {
    setSchoolInfo(allData?.schoolInfo?.data?.[0]);
  }, []);
  const navigate = useNavigate();
  const help = ["faq", "help_and_support"];
  const matchedPages = filterKeyword(allData?.singlePageContent?.data, pages);
  const matchedHelp = filterKeyword(allData?.singlePageContent?.data, help);
  const matchfooter = filterKeyword(allData?.singlePageContent?.data, footer);

  const toggleDesc = (index) => {
    setMore({
      ...more,
      [index]: !more[index],
    });
  };

  return (
    <div className="footer-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-12" data-aos="fade-right">
            <div className="footer_logo_container section-gap">
              <div className="logo_wrapper">
                {schoolInfo?.bg_image_link ? (
                  <img src={schoolInfo?.bg_image_link} alt="#" />
                ) : (
                  <img src={schoolInfo?.image_link} alt="#" />
                )}
              </div>
              <div className="desc">
                {!more[0] && matchfooter?.[0]?.content?.length > 100 ? (
                  <span
                    className="text-decoration-none"
                    dangerouslySetInnerHTML={{
                      __html: matchfooter?.[0]?.content.slice(0, 100) + " ...",
                    }}
                  />
                ) : (
                  <span
                    className="text-decoration-none"
                    dangerouslySetInnerHTML={{
                      __html: matchfooter?.[0]?.content,
                    }}
                  />
                )}

                <br />
                {matchfooter?.[0]?.content?.length > 100 && (
                  <span onClick={() => toggleDesc(0)}>
                    {more[0] ? "Read less" : "Read More"}
                  </span>
                )}
              </div>
              <div className="social_wrapper">
                {socialLogo?.map((social, index) => (
                  <a key={index} target="_blank" href={social?.link}>
                    <div className="social_box">{social?.logo}</div>
                  </a>
                ))}
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-3 col-sm-12 " data-aos="fade-right">
            <div className="list-content section-gap">
              <div className="footer_title">Pages</div>
              <ul>
                {matchedPages?.map((item, index) => (
                  <li key={index}>
                    <a
                      className="footer_list"
                      key={index}
                      href={`#${item?.route}`}>
                      <div>{item?.title}</div>
                    </a>
                  </li>
                ))}

                <li>
                  <a className="footer_list" href="/gallery">
                    <div>Gallery</div>
                  </a>
                </li>
                <li>
                  <a className="footer_list" href="/events">
                    <div>News & Events</div>
                  </a>
                </li>
                <li>
                  <a className="footer_list" href="/admission_form">
                    <div>Admission Form</div>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-md-3 col-sm-12" data-aos="fade-left">
            <div className="list-content section-gap">
              <div className="footer_title">Help</div>
              <ul>
                {matchedHelp?.map((item, index) => (
                  <li>
                    <a
                      className="footer_list"
                      key={index}
                      href={`/${item?.keyword}`}
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}>
                      <div>{item?.title}</div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-md-3 col-sm-12" data-aos="fade-left">
            <div className="contact_us_section section-gap">
              <div className="footer_title">Contact Us</div>
              <div className="contact_list_wrapper">
                <BsTelephone size={18} />
                <div className="contact_list_title">{schoolInfo?.contact}</div>
              </div>
              <div className="contact_list_wrapper">
                <IoMailOutline size={18} />

                <div className="contact_list_title">{schoolInfo?.email}</div>
              </div>
              <div className="contact_list_wrapper">
                <CiLocationOn size={24} />
                <div className="contact_list_title">{schoolInfo?.address}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-section">
        <div className="container">
          <div className="copyright-content">
            <p>
              Copyrights © {currentYear} {schoolInfo?.school_name} | All Rights
              Reserved.
            </p>
            <p
              className="powered-by"
              onClick={() => {
                window.open("https://nivid.app/", "_blank");
              }}>
              Powered By Nivid.app
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterB;

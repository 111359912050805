import React from "react";
import "./RollingAdmissionB.scss";

import { FaArrowRight } from "react-icons/fa";

import { useNavigate } from "react-router-dom";
function RollingAdmissionB() {
  let navigate = useNavigate();

  return (
    <>
      <div className="container">
        <div className="rolling-container">
          <div className="image"></div>
          <div className="rolling-sub-container">
            <h1>Rolling Admissions Now Open</h1>

            <p>"Unlock Your Potential: Ignite Your Future!"</p>
          </div>
          <div
            className="rolling-button"
            onClick={() => {
              navigate("/admission_form");
            }}>
            <button className="rolling-admission-apply">Enroll Now</button>
            <div className="arrow">
              <FaArrowRight color="white" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RollingAdmissionB;

import { useState } from "react";
import "./alumniCarouselB.scss";
import img1 from "../../images/gallary2.png";
import useApiState from "../../store/store";
import Slider from "react-slick";
import { ArrowLeft, ArrowRight } from "tabler-icons-react";
const AlumniCarouselB = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <button className="next-arrow" onClick={onClick}>
        <ArrowRight className="slider-arrows" />
      </button>
    );
  };
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <button className="prev-arrow" onClick={onClick}>
        <ArrowLeft className="slider-arrows" />
      </button>
    );
  };

  const { allData } = useApiState();
  const alumniReviews = allData?.alumniReview?.data;
  const settings = {
    slidesToShow: 3,
    swipeToSlide: true,
    infinite: true,
    focusOnSelect: true,
    dots: true,
    arrows: true,
    autoplay: false, // Enable autoplay
    autoplaySpeed: 3000,
    slidesToScroll: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1049,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {alumniReviews?.map((item, index) => {
        const cutWord =
          item.review.length > 150
            ? item.review.substring(0, 150) + "..."
            : item.review;
        return (
          <>
            <div className="alumni-review">
              <div className="row" style={{ marginTop: "32px" }}>
                <p
                  style={{
                    fontSize: "18px",
                    textTransform: "capitalize",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  School
                </p>
                <p className="descB" style={{ textAlign: "center" }}>
                  <div dangerouslySetInnerHTML={{ __html: cutWord }} />
                </p>
              </div>
            </div>

            <div className="down-triangle"></div>
            <div className="alumni-details">
              <img src={item.image_link} />

              <div>
                <h4 className="name">{item.studentName}</h4>
                <p className="descB">{item.gradeName}</p>
              </div>
            </div>
          </>
        );
      })}
    </Slider>
  );
};

export default AlumniCarouselB;

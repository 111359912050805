import React from "react";
import "./explore.scss";
import { ArrowRight } from "tabler-icons-react";
import { useNavigate } from "react-router-dom";

const Explore = () => {
  let navigate = useNavigate();
  return (
    <div className="exploration-wrapper ">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 col-md-8 col-sm-12">
            <h4 className="title">A day in the life.</h4>
            <p className="descB">
              Please join us as we walk through a day in the life of a Nivid
              School student. 
            </p>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-12">
            <button
              className="viewAll_btn"
              style={{ background: "transparent" }}
              onClick={() => {
                navigate("/gallery");
              }}>
              Explore
              <ArrowRight size={20} style={{ marginBottom: "3px" }} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Explore;

import React from "react";
import "./BannerCover.scss";
import { useNavigate } from "react-router-dom";

export const BannerCover = ({ data, type }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="banner-cover">
        <img
          style={{ height: "90vh" }}
          src={data?.title_image_link}
          alt="heroBanner"
        />
        <div className="hero_desc" data-aos="fade-right">
          <div className="moto">
            <div className="dash"></div>
            <span className="dash_span">{data?.title}</span>
          </div>
          <h1>{data?.subtitle}</h1>

          <button
            onClick={() => {
              navigate("/admission_form");
            }}
            className="learn_more_btn">
            Enroll Now
          </button>
        </div>
      </div>
    </>
  );
};

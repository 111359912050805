import React, { useEffect } from "react";
import Modal from "react-modal";
import { RxCross2 } from "react-icons/rx";
import useApiState from "../../store/store";
import "./HomePopup.scss";
import admission from "../../images/admission.jpg";
import { filterKeyword } from "../../utils/filterKeywords";
import { useNavigate } from "react-router-dom";

const HomePopup = ({ onClose }) => {
  const { allData } = useApiState();
  const matchedData = filterKeyword(allData?.singlePageContent?.data, "popup");

  const customStyles = {
    content: {
      top: "55%",
      left: "50%",
      right: "auto",

      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 1000,
    },
  };

  const navigate = useNavigate();

  return (
    <>
      {matchedData?.[0]?.title_image_link ? (
        <Modal isOpen={true} style={customStyles} ariaHideApp={false}>
          <div className="home-popup-section">
            <div className="popup-header">
              <button className="home-pop-btn" onClick={onClose}>
                <RxCross2 size={30} />
              </button>
            </div>

            <div
              onClick={() => {
                navigate("/admission_form");
              }}
              className="home-pop-content">
              <img
                src={matchedData?.[0]?.title_image_link}
                alt="Popup Content"
              />
            </div>
          </div>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};

export default HomePopup;

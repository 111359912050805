import React from "react";

import "./alumniCarousel.scss";

const AlumniCarouselWrapper = ({
  children,
  showTitle,
  primaryColor,
  borderBottom,
}) => {
  return (
    <div className="container section-gap">
      <div
        className="row text-field"
        style={
          borderBottom
            ? {
                paddingBottom: "24px",
                borderBottom: "1px solid #033348",
                marginBottom: "30px",
              }
            : {}
        }
      >
        <div>
          {showTitle ? (
            <p className="descB" style={{ textTransform: "uppercase" }}>
              Testimonials
            </p>
          ) : null}
          <h3 className="sub_headingB">
            What do{" "}
            <span className={primaryColor ? "getPrimary" : ""}>
              Alumni Say?
            </span>
          </h3>
        </div>
      </div>
      {children}
    </div>
  );
};

export default AlumniCarouselWrapper;

import React from "react";
import GallaryView from "../GallaryB/GallaryView";
import img1 from "../../images/gallary.png";
import img2 from "../../images/gallary2.png";
import img3 from "../../images/gallary3.png";
import img4 from "../../images/gallary5.png";
import useApiState from "../../store/store";

const GalleryD = () => {
  const { allData } = useApiState();
  // const [allImages, setAllImages] = useState([]);

  const images = allData.events?.events.reduce((acc, curr) => {
    if (curr.medias) {
      return acc.concat(curr.medias);
    }
    return acc;
  }, []);

  const allImages = images;

  const filterImages = allImages
    ?.filter((item) => item?.startsWith("https://storage.googleapis.com"))
    ?.slice(0, 6);
  return (
    <div>
      <GallaryView images={filterImages} isGap />
    </div>
  );
};

export default GalleryD;

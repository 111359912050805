import React from "react";
import HeadingB from "../HeadingB/HeadingB";
import "./Testinomial.scss";
import { datas } from "../Data/Data";
import Carousel from "./Caurosel";
function Testinomial() {
  return (
    <>
      <div className="testinomial-container section-gap">
        <div className="testinomail-title-sections">
          <HeadingB title="TESTINOMIAL" subTitle="What Does Our Alumni Say?" icon="true"/>
        </div>
        <div className="alumni-says">
          <Carousel />
        </div>
      </div>
    </>
  );
}

export default Testinomial;

import React from "react";
import "./OurProgramB.scss";
import { filterKeyword } from "../../utils/filterKeywords";
import useApiState from "../../store/store";

const OurProgramB = () => {
  const { allData } = useApiState();
  const academics = ["montessori", "school", "secondary", "plus_2", "programs"];
  const matchedItems = filterKeyword(
    allData?.singlePageContent?.data,
    academics
  );
  const sortMatchedItems = matchedItems
    ?.filter((item) => [17, 12, 25].includes(item?.id))
    .sort((a, b) => [17, 12, 25].indexOf(a.id) - [17, 12, 25].indexOf(b.id));

  return (
    <div className="section-gap">
      <section className="OurProgramB_container" id="academics">
        <div className="container">
          <div className="OurProgramB_wrapper">
            <div className="row">
              <div className="col-md-12 col-xl-8 col-sm-12 order-smm-2">
                <div className="program_box_wrapper ">
                  <div className="row">
                    {sortMatchedItems?.map((item, index) => (
                      <div className="col-md-3 main-content">
                        <div
                          key={index}
                          className="program_box"
                          data-aos="fade-right">
                          <div className="title">{item?.title}</div>
                          <div className="descB desc">{item?.subtitle}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-xl-4 col-sm-12 order-smm-1">
                <div className="program_title_wrapper" data-aos="fade-left">
                  <div className="headingB">
                    <div className="line"></div>
                    <div className="title">our programs</div>
                  </div>
                  <h2>
                    Discover our top-notch programs we are home for pupils
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OurProgramB;

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./AllHeaders.scss";
import { IoSearchOutline } from "react-icons/io5";
// import { FaFacebookF } from "react-icons/fa";
// import { CiLinkedin } from "react-icons/ci";
// import { FaYoutube } from "react-icons/fa";

import { useNavigate } from "react-router-dom";
import { filterKeyword } from "../../utils/filterKeywords";
import useApiState from "../../store/store";
import { List } from "../../utils/List";
import { IoHome } from "react-icons/io5";
const academics = ["montessori", "school", "plus_2", "bachelors"];

const aboutUs = [
  "introduction",
  "vision",
  "mission",
  "principal_statement",
  "goal",
  "chairman_statement",
  "approach",
  "core_value",
  "philosophy",
  "principles",
];
const AllHeaders = () => {
  const { allData } = useApiState();
  const matchedItems = filterKeyword(
    allData?.singlePageContent?.data,
    academics
  );
  const matchedItems2 = allData?.singlePageContent?.data?.filter((item) =>
    item?.keyword.startsWith("plus_2_")
  );
  const matchedItems3 = allData?.singlePageContent?.data?.filter((item) =>
    item?.keyword.startsWith("bachelors_")
  );

  const academicHeader = matchedItems;
  const [isNavOpen, setIsNavOpen] = useState(false);
  const location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    setIsNavOpen(false);
  }, [location]);

  const matchedheaders = filterKeyword(
    allData?.singlePageContent?.data,
    aboutUs
  );

  const specificHeaders = matchedheaders
    ?.filter((item) => [4, 8, 11, 10].includes(item?.id))
    .sort(   
      (a, b) => [4, 8, 11, 10].indexOf(a.id) - [4, 8, 11, 10].indexOf(b.id)
    );

  const matchedadmissions = filterKeyword(allData?.singlePageContent?.data, [
    "enrollment",
    "admission_document",
  ]);

  return (
    <header className="all_headers_container">
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container navbar_container">
          <Link
            // style={{ marginRight: "50px" }}
            className="navbar-brand"
            to="/"
            onClick={() => {
              window.scrollTo(0, 0);
            }}>
            {allData?.schoolInfo?.data?.[0]?.image_link ? (
              <img
                style={{ height: "50px" }}
                src={allData?.schoolInfo?.data?.[0]?.image_link}
                alt="logo"
              />
            ) : (
              <img
                style={{ height: "50px" }}
                src={require("../../images/nivid.png")}
                alt="logo"
              />
            )}
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded={isNavOpen ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={() => setIsNavOpen(!isNavOpen)}>
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className={`collapse navbar-collapse ${isNavOpen ? "show" : ""}`}
            id="navbarNavDropdown">
            <ul className="navbar-nav ms-auto">
              <p
                onClick={() => {
                  navigate("/");
                }}>
                <IoHome className="home_icon"  />
              </p>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle header_title"
                  to="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  About
                </Link>
                <ul className="dropdown-menu">
                  {specificHeaders?.map((item, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        setIsNavOpen(false);
                        navigate(`/${item.keyword}`);
                      }}>
                      <Link className="dropdown-item header_title ">
                        {item.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle header_title"
                  to="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  Academics
                </Link>
                <ul className="dropdown-menu">
                  <List
                    of={academicHeader}
                    render={(item, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setIsNavOpen(false);
                          navigate(`/${item?.keyword}`);
                        }}>
                        <Link className="dropdown-item header_title" to="#">
                          {item?.title}
                        </Link>
                      </li>
                    )}
                  />
                </ul>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle header_title"
                  to="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  Plus 2
                </Link>
                <ul className="dropdown-menu">
                  <List
                    of={matchedItems2}
                    render={(item, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setIsNavOpen(false);
                          navigate(`/${item?.keyword}`);
                        }}>
                        <Link className="dropdown-item header_title" to="#">
                          {item?.title}
                        </Link>
                      </li>
                    )}
                  />
                </ul>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle header_title"
                  to="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  Bachelor
                </Link>
                <ul className="dropdown-menu">
                  <List
                    of={matchedItems3}
                    render={(item, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setIsNavOpen(false);
                          navigate(`/${item?.keyword}`);
                        }}>
                        <Link className="dropdown-item header_title" to="#">
                          {item?.title}
                        </Link>
                      </li>
                    )}
                  />
                </ul>
              </li>

              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle header_title"
                  to="/contact"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  Admissions
                </Link>
                <ul className="dropdown-menu">
                  {matchedadmissions?.map((item, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        setIsNavOpen(false);
                        navigate(`/${item.keyword}`);
                      }}>
                      <Link className="dropdown-item header_title">
                        {item.title}
                      </Link>
                    </li>
                  ))}
                  <li>
                    <a href="#contact" className="dropdown-item header_title">
                      Contact Us
                    </a>
                  </li>
                  <li
                    onClick={() => {
                      setIsNavOpen(false);
                      navigate("/admission_form");
                    }}>
                    <Link className="dropdown-item header_title">
                      Admission Form
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link text-nowrap header_title"
                  href="/events"
                  aria-current="page">
                  News & Events
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link header_title"
                  href="/gallery"
                  aria-current="page">
                  Gallery
                </a>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link active" to="#" aria-current="page">
                  <IoSearchOutline />
                </Link>
              </li> */}

              <li>
                {/* <a href="#contact">
                  <button type="button" className="btn contact_btn">
                    Contact
                  </button>
                </a> */}

                <a href="/admission_form">
                  <button
                    type="button"
                    className="btn contact_btn header_title">
                    Enroll Now
                  </button>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default AllHeaders;

import React from "react";
import "./ImageContent.scss";
import { useNavigate, useLocation } from "react-router-dom";
import useApiState from "../../store/store";
import { FaArrowRightLong } from "react-icons/fa6";
import Heading from "../Heading/Heading";
import { Button, Tooltip } from "@mui/material";
export const ImageContent = ({ data, textMin }) => {
  const { allData } = useApiState();
  const navigate = useNavigate();
  const location = useLocation();
  const matchedItems = allData?.singlePageContent.data?.filter((item) =>
    item?.keyword.startsWith("plus_2_")
  );
  const bachelorsItems = allData?.singlePageContent.data?.filter((item) =>
    item?.keyword.startsWith("bachelors_")
  );

  // Check if the current location is the home page
  const isHomePage = location.pathname === "/";

  let itemsToRender = null;
  if (location.pathname === "/bachelors") {
    itemsToRender = bachelorsItems;
  } else if (location.pathname === "/plus_2") {
    itemsToRender = matchedItems;
  }

  return (
    <div className={`image-content-section ${textMin ? "mt-20" : ""}`}>
      <div className="container">
        <div className="image_card">
          {data?.image_link !== "" && <img src={data?.image_link} alt="img" />}

          {textMin && (
            <p className="principal_name">
              {data?.subtitle} <br /> (Principal)
            </p>
          )}
        </div>
        <div className={`content ${textMin && "min-text-block"}`}>
          {isHomePage ? (
            <p
              dangerouslySetInnerHTML={{
                __html: data?.content.slice(0, 230).trim() + "....",
              }}
            />
          ) : (
            <>
              <p
                dangerouslySetInnerHTML={{
                  __html: data?.content,
                }}
              />
            </>
          )}
          {isHomePage && (
            <button
              className="read_btn"
              onClick={() => {
                navigate("/principal_statement");
              }}>
              Read More
            </button>
          )}
        </div>
        <div className="more_section_container ">
          {itemsToRender && <Heading subtitle="Our Programs" />}

          {itemsToRender?.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  navigate(`/${item?.keyword}`);
                }}
                className="more_section">
                <p className="more_section_title">{item.title}</p>
                <Tooltip title="Our Programs">
                  <Button>
                    <FaArrowRightLong color="white" className="next_icon" />
                  </Button>
                </Tooltip>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

import React from "react";
import { BannerBottom } from "../BannerBottom/BannerBottom";
import { BannerCover } from "../BannerCover/BannerCover";
import "./SliderCover.scss";
import Slider from "react-slick";
import useApiState from "../../store/store";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
export const SliderCover = () => {
  const { allData } = useApiState();

  const matchedItems = allData?.singlePageContent?.data?.filter((item) =>
    item?.keyword.startsWith("Cover")
  );
  const NextArrow = ({ onClick, className }) => {
    return (
      <button className="slider-banner-next-arrow" onClick={onClick}>
        <MdOutlineKeyboardArrowRight color="white" size={30} />
      </button>
    );
  };
  const PrevArrow = ({ onClick }) => {
    return (
      <button className="slider-banner-prev-arrow" onClick={onClick}>
        <MdOutlineKeyboardArrowLeft color="white" size={30} />
      </button>
    );
  };
  const settings = {
    slidesToShow: 1,
    swipeToSlide: true,
    infinite: true,
    focusOnSelect: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="slider-cover-section">
      <Slider {...settings}>
        {matchedItems?.map((item, index) => {
          return <BannerCover key={index} data={item} />;
        })}
      </Slider>

      <BannerBottom />
    </div>
  );
};

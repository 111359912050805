import React, { useEffect, useRef, useState } from "react";
import "./BannerContent.scss";
import HeadingB from "../HeadingB/HeadingB";
import { ImageContent } from "../ImageContent/ImageContent";
import { AdmissionInquiry } from "../AdmissionInquiry/AdmissionInquiry";
import { useParams } from "react-router-dom";

import useApiState from "../../store/store";
import { MetaConfigure } from "../helmet";
import PageNotFound from "../PageNotFound/PageNotFound";

export const BannerContent = () => {
  const [pageDetail, setPageDetails] = useState();
  const { route } = useParams();
  const { allData } = useApiState();

  window.scrollTo(0, 0);

  const myRef = useRef(null);

  useEffect(() => {
    if (myRef.current) {
      myRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    if (route) {
      const responseData = allData.singlePageContent.data;
      const pageData = responseData?.filter((item) => item.keyword === route);

      setPageDetails(pageData?.[0]);
    }
  }, [route]);

  if (pageDetail === null || pageDetail === undefined) {
    return <PageNotFound />;
  }

  return (
    <div className="banner-content-section">
      <MetaConfigure title={pageDetail?.title} />
      <div className="banner-image-area">
        <img src={pageDetail?.title_image_link} alt="banner-img" />
        <div className="overlay"></div>
        <div className="sub-banner-image">
          <HeadingB
            color="#FFFFFF"
            title={pageDetail?.title}
            subTitle={pageDetail?.subtitle}
          />
        </div>
      </div>
      <ImageContent data={pageDetail} />
    </div>
  );
};

import React from 'react'
import "./PageNotFound.scss"
import notFoundImg from "../../images/404.svg"

const PageNotFound = () => {
  return (
    <div className='page_not_found_wrapper'>
        <div>
        <div className="img_wrapper">
            <img src={notFoundImg} alt="" />

        </div>
        <h2 className='banner_desc'>
            PAGE NOT FOUND
        </h2>

        </div>
   

    </div>
  )
}

export default PageNotFound
import React from "react";
import "./HeadingB.scss";

const HeadingB = ({ title, subTitle, color, icon }) => {
  return (
    <div className="headingB_container" data-aos="fade-right">
      <div className="headingB">
        {icon ? <div style={{ background: color }} className="line"></div> : ""}
        <div style={{ color: color }} className="container_title">
          {title}
        </div>
      </div>
      <div style={{ color: color }} className="sub_headingB">
        {subTitle}
      </div>
    </div>
  );
};

export default HeadingB;

import React, { useState, useEffect } from "react";
import "./WhatSetsUs.scss";
import signature from "../../images/signature.png";
import useApiState from "../../store/store";
import { filterKeyword } from "../../utils/filterKeywords";

const WhatSetsUs = () => {
  const [webText, setWebText] = useState();
  const { allData } = useApiState();

  const about_us = filterKeyword(allData?.singlePageContent?.data, "header");

  useEffect(() => {
    setWebText(allData?.webPageText?.data?.[0]);
  }, []);
  return (
    <section className="container">
      <div className="what_sets_us_container" data-aos="fade-down">
        <h2>What sets us apart</h2>
        <div className="desc_wrapper">
          <div
            dangerouslySetInnerHTML={{ __html: about_us?.[0]?.content }}></div>
          {/* <img src={webText?.signature_link} alt="signature" /> */}
        </div>
      </div>
    </section>
  );
};

export default WhatSetsUs;

import React from "react";
import "./TestimonialBlock.scss";
import Heading from "../Heading/Heading";

const TestimonialBlock = () => {
  return (
    <div className="testimonial_block_container">
      <Heading title="testimonials" subtitle="what does our alumini say?" />
      
    </div>
  );
};

export default TestimonialBlock;

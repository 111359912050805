import React from "react";
import first from "../../images/Application1.png";
import second from "../../images/Application2.png";
import third from "../../images/Application3.png";
import "./applicationInformation.scss";
import useApiState from "../../store/store";
import Newscard from "../UI/NewsCard/Newscard";
const datas = [
  {
    image: first,
    title: "For applicants to Grades 8-10",
    desc: "We require applications, recommendations and the transcript/report card  to be submitted online in keeping with commitment to sustainability and  the ease and reliability of this format. ",
    apply: "For applicants to K-5: Sheetal Shrestha |",
    link: "sheetal.shrestha@nividschool.com",
  },
  {
    image: second,
    title: "For applicants to Grades 8-10",
    desc: "We require applications, recommendations and the transcript/report card  to be submitted online in keeping with commitment to sustainability and  the ease and reliability of this format. ",
    apply: "For applicants to K-5: Sheetal Shrestha |",
    link: "sheetal.shrestha@nividschool.com",
  },
  {
    image: third,
    title: "Grade Specific Information",
    desc: "It is the applicant/prospective family’s responsibility to follow up  with the Admission Office in a timely manner regarding the status of the  applicant’s file. If the file is incomplete, it is the responsibility.",
    apply: "Please contact the Admission Office:",
    link: "+977-9841123456",
  },
];
function ApplicationInformation() {
  const { allData } = useApiState();
  const featuredStories = allData?.schoolWalls?.data;
  return (
    <>
      <div className="container section-gap">
        <div className="application">
          <div className="row">
            {featuredStories?.map((item, index) => {
              const value = index % 2 === 0 ? "fade-left" : "fade-right";
              return (
                <div className="col-lg-4 col-md-4 col-sm-12" data-aso={value}>
                  <Newscard
                    date={item?.postedDate}
                    img={item?.post_image}
                    heading={item?.student_image}
                    details={item?.description}
                    key={index}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default ApplicationInformation;

import React, { useEffect, useState } from "react";
import "./StudentAdmissionForm.scss";

import * as Yup from "yup";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { admissionForm } from "../../Api/PostApi";
import { MetaConfigure } from "../helmet";

import { normalizeUnits } from "moment";
import { toast } from "react-toastify";
export const StudentAdmissionForm = () => {
  const [appliedFor, setAppliedFor] = useState("school");
  const [submittedValues, setSubmittedValues] = useState(null);
  // Validation schema
  const validationSchema = Yup.object().shape({
    student_table_requests: Yup.object().shape({
      student_name: Yup.string().required("Student's Name is required"),

      dob: Yup.date().required("Date of Birth is required"),
      email: Yup.string().email("Invalid email").required("Email is required"),
      mobile: Yup.string()
        .required("Mobile Number is required")
        .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits"),
      permanent_address: Yup.string().required("Permanent Address is required"),
      temporary_address: Yup.string().required("Temporary Address is required"),
    }),
  });
  const initialValues = {
    student_table_requests: {
      student_name: "",
      gender: "",
      dob: "",
      dob_token: "",
      grade_token: "",
      email: "",
      permanent_address: "",
      mobile: "",
      temporary_address: "",
      nationality: "",
      father_name: "",
      father_token: "",
      mother_name: "",
    },
    additional_infos: {
      school_address: "",
      father_occupation: "",
      mother_occupation: "",
      father_office_number: "",
      mother_office_number: "",
      father_mobile_number: "",
      mother_mobile_number: "",
      comment: "",
      father_email_address: "",
      mother_email_address: "",
      previous_school_address: "",
      previous_school_name: "",
      see_appeared_year: "",
      total_gpa_or_percentage_of_see: "",
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="student-admission-form section-gap">
      <MetaConfigure title="Admission Form" />

      <div className="container">
        <div className="header-section">
          {/* <img
            src="https://kavreschool.edu.np/assets/pic/school-header-ac50e631927edebdf41c13831931203f8159437155124618fea29a3ff02f83c3.png"
            alt="logo"
          /> */}

          <h4 className="main-header">Online Application Form</h4>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            const { student_table_requests, additional_infos } = values;

            admissionForm({
              student_table_requests: student_table_requests,
              additional_infos: additional_infos,
            })
              .then((res) => {
                setSubmittedValues(values);

                toast("Admission Form Submit Sucessfully");
              })
              .catch((error) => {
                console.error("Error:", error);
              })
              .finally(() => {
                setSubmitting(false);
                resetForm();
              });
          }}>
          {({ isSubmitting }) => (
            <Form>
              <div className="form-section">
                <p className="form-title">Personal Information</p>

                <div className="form-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          Student's Name <span>*</span>
                        </label>
                        <Field
                          type="text"
                          name="student_table_requests.student_name"
                          className="form-control"
                          placeholder="Full Name"
                        />
                        <ErrorMessage
                          name="student_table_requests.student_name"
                          component="div"
                          className="error-message text-danger small"
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Gender</label>
                        <Field
                          name="student_table_requests.gender"
                          as="select"
                          className="form-control">
                          <option value="">Select Gender</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="other">Other</option>
                        </Field>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Date of Birth</label>
                        <Field
                          type="date"
                          name="student_table_requests.dob"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="student_table_requests.dob"
                          component="div"
                          className="error-message  text-danger small"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Email</label>
                        <Field
                          type="email"
                          name="student_table_requests.email"
                          className="form-control"
                          placeholder="Email Address"
                        />
                        <ErrorMessage
                          name="student_table_requests.email"
                          component="div"
                          className="error-message  text-danger small"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Mobile</label>
                        <Field
                          type="number"
                          name="student_table_requests.mobile"
                          className="form-control"
                          placeholder="Mobile Number"
                        />
                        <ErrorMessage
                          name="student_table_requests.mobile"
                          component="div"
                          className="error-message  text-danger small"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Permanent Address</label>
                        <Field
                          type="text"
                          name="student_table_requests.permanent_address"
                          className="form-control"
                          placeholder="Permanent Address"
                        />
                        <ErrorMessage
                          name="student_table_requests.permanent_address"
                          component="div"
                          className="error-message  text-danger small"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Temporary Address</label>
                        <Field
                          type="text"
                          name="student_table_requests.temporary_address"
                          className="form-control"
                          placeholder="Temporary Address"
                        />
                        <ErrorMessage
                          name="student_table_requests.temporary_address"
                          component="div"
                          className="error-message  text-danger small"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Nationality</label>
                        <Field
                          type="text"
                          name="student_table_requests.nationality"
                          className="form-control"
                          placeholder="Nationality"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-section">
                <p className="form-title">Academic Information</p>

                <div className="form-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Applied For</label>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="content-wrap">
                              <Field
                                type="radio"
                                name="applied_for"
                                checked={appliedFor === "plus2"}
                                value="plus2"
                                onChange={(e) => {
                                  setAppliedFor(e.target.value);
                                }}
                              />
                              Plus 2 Programme
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="content-wrap">
                              <Field
                                type="radio"
                                name="program"
                                value="school"
                                checked={appliedFor === "school"}
                                onChange={(e) => {
                                  setAppliedFor(e.target.value);
                                }}
                              />
                              School
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {appliedFor === "plus2" ? (
                      <>
                        <div className="col-md-6">
                          <label>Stream Applied For</label>

                          <div className="radio-section">
                            <div className="content-wrap">
                              <Field
                                type="radio"
                                name="stream"
                                value="management"
                              />
                              Management
                              <ErrorMessage
                                name="stream"
                                component="div"
                                className="error-message  text-danger small"
                              />
                            </div>
                            <div className="content-wrap">
                              <Field
                                type="radio"
                                name="stream"
                                value="science"
                              />
                              Science
                              <ErrorMessage
                                name="stream"
                                component="div"
                                className="error-message  text-danger small"
                              />
                            </div>
                            <div className="content-wrap">
                              <Field type="radio" name="stream" value="law" />
                              Law
                              <ErrorMessage
                                name="stream"
                                component="div"
                                className="error-message  text-danger small"
                              />
                            </div>
                            <div className="content-wrap">
                              <Field
                                type="radio"
                                name="stream"
                                value="humanities"
                              />
                              Humanities
                              <ErrorMessage
                                name="stream"
                                component="div"
                                className="error-message  text-danger small"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Total GPA or Percentage of SEE</label>
                            <Field
                              type="text"
                              name="additional_infos.total_gpa_or_percentage_of_see"
                              className="form-control "
                              placeholder="Obtained Percentage/GPA"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>SEE Appeared Year</label>
                            <Field
                              type="text"
                              name="additional_infos.see_appeared_year"
                              className="form-control "
                              placeholder="SEE Apeared Year"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Name of SEE Appeared School</label>
                            <Field
                              type="text"
                              name="additional_infos.name_appear_school"
                              className="form-control "
                              placeholder="Obtained Percentage/GPA"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>School Address</label>
                            <Field
                              type="text"
                              name="additional_infos.school_address"
                              className="form-control "
                              placeholder="School Address"
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Admission Seeking for Class</label>
                            <Field
                              as="select"
                              name="student_table_requests.grade_token"
                              className="form-control "
                              placeholder="class Name">
                              <option value="" label="Select Class" />

                              <option value="1" label="One" />
                              <option value="2" label="Two" />
                              <option value="3" label="Three" />
                              <option value="4" label="Four" />
                              <option value="5" label="Five" />
                              <option value="6" label="Six" />
                              <option value="7" label="Seven" />
                              <option value="8" label="Eight" />
                              <option value="9" label="Nine" />
                              <option value="10" label="Ten" />
                              <option value="11" label="Eleven" />
                              <option value="12" label="Twelve" />
                            </Field>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Last obtained Percentage/Grade Point</label>
                            <Field
                              type="text"
                              name="additional_infos.last_obtained_percentage_grade_point"
                              className="form-control "
                              placeholder="Obtained percentage/GPA"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Previous School Name</label>
                            <Field
                              type="text"
                              name="additional_infos.previous_school_name"
                              className="form-control "
                              placeholder="School Name"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Previous School Address</label>
                            <Field
                              type="text"
                              name="additional_infos.previous_school_address"
                              className="form-control "
                              placeholder="School Address"
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-section">
                <p className="form-title">Family Information</p>

                <div className="form-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>*Father Name</label>
                        <Field
                          type="text"
                          name="student_table_requests.father_name"
                          className="form-control"
                          placeholder="Father Name"
                        />
                        <ErrorMessage
                          name="student_table_requests.father_name"
                          component="div"
                          className="error-message  text-danger small"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Mother Name</label>
                        <Field
                          type="text"
                          name="student_table_requests.mother_name"
                          className="form-control"
                          placeholder="Mother Name"
                        />
                        <ErrorMessage
                          name="student_table_requests.mother_name"
                          component="div"
                          className="error-message  text-danger small"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Father Occupation</label>
                        <Field
                          type="text"
                          name="additional_infos.father_occupation"
                          className="form-control"
                          placeholder="Occupation"
                        />
                        <ErrorMessage
                          name="additional_infos.father_occupation"
                          component="div"
                          className="error-message  text-danger small"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Mother Occupation</label>
                        <Field
                          type="text"
                          name="additional_infos.mother_occupation"
                          className="form-control"
                          placeholder="Occupation"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Father Office Number</label>
                        <Field
                          name="additional_infos.father_office_number"
                          type="number"
                          className="form-control"
                          placeholder="Telephone"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Mother Office Number</label>
                        <Field
                          type="integer"
                          name="additional_infos.mother_office_number"
                          className="form-control"
                          placeholder="Telephone"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Father Mobile Number</label>
                        <Field
                          type="integer"
                          name="additional_infos.father_mobile_number"
                          className="form-control"
                          placeholder="Mobile Number"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Mother Mobile Number</label>
                        <Field
                          type="number"
                          name="additional_infos.mother_mobile_number"
                          className="form-control"
                          placeholder="Mobile Number"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Father Email Address</label>
                        <Field
                          type="text"
                          name="additional_infos.father_email_address"
                          className="form-control"
                          placeholder="Email Address"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Mother Email Address</label>
                        <Field
                          type="text"
                          name="additional_infos.mother_email_address"
                          className="form-control"
                          placeholder="Email Address"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-section">
                <p className="form-title">Other Information</p>

                <div className="form-body">
                  <div className="term-section">
                    <Field type="checkbox" name="bus" />
                    <h4>Tick if you use School Bus</h4>
                  </div>

                  <div>
                    <label>Do you have any other inquiries ?</label>

                    <Field
                      className="form-control"
                      name="additional_infos.comment"
                      component="textarea"
                      placeholder="Comments"
                    />
                  </div>
                </div>
              </div>

              <div className="term-section">
                <Field type="checkbox" />
                <p>
                  I certify that the information presented in my application is
                  accurate, complete and honestly presented and has been filled
                  by me. I understand and agree that any inaccurate or
                  misleading information, or omission will be cause for revoking
                  any offer of admission, or for disciplinary action, dismissal
                  or recocal of degree if discovered at a later date. The
                  Admissions Committee may verif any and all part of my
                  application materials. I agree the abide by the rules and
                  regulations of the school in letter and spirit, if I accept
                  the offer of admission.
                </p>
              </div>
              <div className="button-area">
                <button className="btn submit-btn" type="submit">
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

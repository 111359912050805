import React, { useEffect, useState } from "react";
import useApiState from "../../store/store";
import GallaryView from "./GallaryView";

const GallaryB = () => {
  const { allData } = useApiState();
  // const [allImages, setAllImages] = useState([]);

  const images = allData?.events?.events.reduce((acc, curr) => {
    if (curr.medias) {
      return acc.concat(curr.medias);
    }
    return acc;
  }, []);

  const allImages = images;

  const filterImages = allImages
    ?.filter((item) => item?.startsWith("https://storage.googleapis.com"))
    ?.slice(0, 6);

  return <GallaryView images={filterImages} />;
};

export default GallaryB;

import React from "react";
import "./Heading.scss";

const Heading = ({ title, subtitle }) => {
  return (
    <div className="heading_wrapper">
      <div className="title">{title}</div>
      <div className="sub_title">{subtitle}</div>
    </div>
  );
};

export default Heading;

import React, { useState } from "react";
import "./NewsCard.scss";
import { FaCalendarAlt } from "react-icons/fa";
import news from "../../../images/news-default.png";
import Modal from "react-modal";
import { RxCross2 } from "react-icons/rx";
const Newscard = ({
  img,
  date,
  heading,
  details,
  data_id,
  handleSelect,
  selectedData,
}) => {
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 1000,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      width: "90%",
      height: "100%",
      overflowY: "scroll",
      marginTop: "50px",

      backgroundColor: "white",
    },
  };

  const title = heading;
  const content = details;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="newsCard_container">
      <div
        onClick={() => {
          openModal();
          handleSelect(data_id);
        }}
        className="img_container">
        {img ? <img src={img} alt="news_image" /> : <img src={news} />}
      </div>

      <div className="news_card_content_wrapper">
        <div className="icon-block">
          <FaCalendarAlt />
          <span>{date}</span>
        </div>
        <div className="title_desc_wrapper">
          <div className="title">
            <div dangerouslySetInnerHTML={{ __html: title }}></div>
          </div>
          <div className="desc">
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
        <div className="modal-content">
          <div className="news_popup_title">
            <p className="sub_headingB">{selectedData?.[0]?.postedDate}</p>
            <button className="news_close" onClick={closeModal}>
              <RxCross2 size={30} />
            </button>
          </div>
          <div className="event_modal_section">
            {selectedData?.[0]?.post_image ? (
              <img src={selectedData?.[0]?.post_image} alt="image" />
            ) : (
              <img src={news} />
            )}

            <p className="desc p-3">{selectedData?.[0]?.description}</p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Newscard;

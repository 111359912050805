import React from "react";
import AboutUsB from "../../components/AboutUsB/AboutUsB";
import GallaryB from "../../components/GallaryB/GallaryB";
import OurBenifits from "../../components/OurBenifitsB/OurBenifitsB";
import OurProgramB from "../../components/OurProgramB/OurProgramB";
import { RenderComponent } from "../../components/RenderComponent";
import TeamB from "../../components/TeamB/TeamB";
import Testinomial from "../../components/Testinominal/Testinomial";
import WhatSetsUs from "../../components/WhatSetsUs/WhatSetsUs";
import "./LandingPage.scss";
// import MetaConfigure from "../../components/helmet";
import { MetaConfigure } from "../../components/helmet";
import WhatSetsUsParallax from "../../components/WhatSetUsParallax/WhatSetsUsParallax";
import OurProgramBParallax from "../../components/OurProgramBParallax/OurProgramBParallax";
import FeatureSlider from "../../components/FeatureSlider/FeatureSlider";

import BannerEnrollMent from "../../components/HeroBanner/BannerEnrollment/BannerEnrollment";
import {
  Alumni1,
  Alumni2,
} from "../../components/AlumniCarousel/AlumniCarouselReusableWrapper";
import RollingAdmissionB from "../../components/RollingAdmissionB/RollingAdmissionB";
import { SliderBanner } from "../../components/HeroBanner/SliderBanner/SliderBanner";
import { BenefitsContainerView } from "../../components/BenefitsConntainerView/BenefitsContainerView";
import GalleryD from "../../components/GalleryD/GalleryD";
import Explore from "../../components/ExploreJoinUs/Explore";
import Curriculum from "../../components/Curriculum/Curriculum";
import TestimonialBlock from "../../components/TestimonialBlock/TestimonialBlock";
import WelcomeBanner from "../../components/WelcomeBanner/WelcomeBanner";
import SchoolInfo from "../../components/SchoolInfo/SchoolInfo";
import ExploreB from "../../components/ExploreB/ExploreB";
import AdmissionDate from "../../components/AdmissionDate/AdmissionDate";
import SchoolLevel from "../../components/SchoolLevel/SchoolLevel";
import WhyChooseUs from "../../components/WhyChooseUs/WhyChooseUs";
import SchoolFeatures from "../../components/SchoolFeatures/SchoolFeatures";
import ApplicationInformation from "../../components/ApplicationInformation/ApplicationInformation";
import { PrincipalMessage } from "../../components/PrincipalMessage/PrincipalMessage";
import WorkedWithUs from "../../components/WorkedWithUs/WorkedWithUs";
import { SliderCover } from "../../components/SliderCover/SliderCover";
import NewsB from "../../components/NewsB/NewsB";
import RollingAdmission from "../../components/RollingAdmission/RollingAdmission";
import HelpSection from "../../components/HelpSection/HelpSection";
import { TopHeader } from "../../components/TopHeader/TopHeader";

const LandingPage = () => {
  return (
    <div>
      <MetaConfigure title="Home" />
      {/* Banner */}
      {/* <RenderComponent component={HeroBannerB} keyName="banner-B" /> */}
      <RenderComponent component={SliderCover} keyName="banner-B" />

      <RenderComponent
        component={BannerEnrollMent}
        keyName="banner-enrollment"
      />

      <RenderComponent component={SliderBanner} keyName="banner-slider" />
      {/* what set us  */}

      <RenderComponent component={WhatSetsUs} keyName="what-set-us" />
      <RenderComponent
        component={WhatSetsUsParallax}
        keyName="what-set-us-parallax"
      />
      <RenderComponent
        component={BenefitsContainerView}
        keyName="our-benifits-C"
      />
      {/* About */}

      <RenderComponent component={AboutUsB} keyName="about-us-B" />
      {/* Help section*/}
      <RenderComponent component={HelpSection} keyName={"help-section"} />

      {/* Benefits */}

      <RenderComponent component={OurBenifits} keyName="our-benifits-B" />

      {/* Curriculum  */}
      <RenderComponent component={Curriculum} keyName="curriculum" />
      {/* Explore  */}
      <RenderComponent component={Explore} keyName="explore" />

      {/* Gallery */}

      <RenderComponent component={GalleryD} keyName="gallary-D" />
      <RenderComponent component={TeamB} keyName="team-B" />
      <RenderComponent component={GallaryB} keyName="gallary-B" />

      {/* principal message */}

      <RenderComponent
        component={PrincipalMessage}
        keyName="principal_statement"
      />
      {/* {Worked with us} */}
      <RenderComponent component={WorkedWithUs} keyName="work-with" />
      {/* Testamonials */}

      <RenderComponent component={Testinomial} keyName="testamonials" />
      <RenderComponent
        component={TestimonialBlock}
        keyName="testimonial-block"
      />

      {/* Alumni Carousel */}
      <RenderComponent component={Alumni1} keyName="alumni-carousel" />
      <RenderComponent component={Alumni2} keyName="alumni-carousel-2" />

      {/* Programs */}

      <RenderComponent component={OurProgramB} keyName="our-program-B" />
      <RenderComponent
        component={OurProgramBParallax}
        keyName="our-program-B-parallax"
      />

      {/* Explore and Join us */}

      {/*features */}

      <RenderComponent component={FeatureSlider} keyName="slider" />
      {/* Testamonials */}

      <RenderComponent
        component={TestimonialBlock}
        keyName="testimonial-block"
      />
      <RenderComponent component={WelcomeBanner} keyName="welcome-banner" />
      <RenderComponent component={AdmissionDate} keyName="admission-open" />
      <RenderComponent component={SchoolInfo} keyName="school-info" />
      <RenderComponent component={NewsB} keyName="news-B" />

      <RenderComponent component={ExploreB} keyName="explore-B" />
      {/* Get started */}
      {/*Rollig admissiom B*/}
      <RenderComponent
        component={RollingAdmissionB}
        keyName="rolling-admissiom-B"
      />
      {/* lates news and events */}

      <RenderComponent component={SchoolLevel} keyName="school_level" />
      <RenderComponent component={WhyChooseUs} keyName="choose-us" />
      <RenderComponent component={SchoolFeatures} keyName="school-features" />
      <RenderComponent
        component={ApplicationInformation}
        keyName="application-information"
      />
    </div>
  );
};

export default LandingPage;

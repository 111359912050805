import React from "react";
import useApiState from "../../store/store";
import { filterKeyword } from "../../utils/filterKeywords";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./WorkedWithUs.scss";
import HeadingB from "../HeadingB/HeadingB";

function WorkedWithUs() {
  const { allData } = useApiState();
  const matchedItems = allData?.singlePageContent?.data?.filter((item) =>
    item?.keyword.startsWith("patron")
  );

  var settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 200,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="container mb-4  section-gap">
        <HeadingB title="" subTitle="Who we've worked with" />
        <div className="client_section">
          <Slider {...settings}>
            {matchedItems?.map((data, index) => (
              <div key={index} className="client_card">
                <img src={data.image_link} alt="" />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
}

export default WorkedWithUs;

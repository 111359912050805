import React, { useState } from "react";
import "./welcomeBanner.scss";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import welcome from "../../images/welcome.png";
import { BsPlus } from "react-icons/bs";
import { HiMinus } from "react-icons/hi";

const WelcomeBanner = () => {
  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const accordionSets = [
    {
      accordionName: "Getting Started",
      accordionDesc:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi",
    },
    {
      accordionName: "At a Glance",
      accordionDesc:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi",
    },
    {
      accordionName: "Apply Online",
      accordionDesc:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi",
    },
    {
      accordionName: "Admission Documents",
      accordionDesc:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi",
    },
  ];

  return (
    <div className="container section-gap">
      <div className="welcome-banner">
        <div className="row">
          <div className="col-md-6 col-sm-12 left-wrap">
            <div className="text-container">
              <h3 className="sub_headingB">
                Welcome to <span className="school-name">Nivid School</span>
              </h3>
              <p className="descB">
                Our students are talented, hard-working and full of good ideas.
                We encourage and empower them to bring their ideas to life.
                Hands-on opportunities are what we're all about.
              </p>
            </div>
            <div className="accordion">
              {accordionSets?.map((acc, index) => (
                <Accordion
                  expanded={expanded === `{panel${index}}`}
                  onChange={handleChange(`{panel${index}}`)}
                  style={{ marginBottom: "12px" }}
                >
                  <AccordionSummary
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    {expanded === `{panel${index}}` ? (
                      <HiMinus
                        size={24}
                        style={{
                          marginBottom: "4px",
                          marginLeft: "-10px",
                          marginRight: "10px",
                        }}
                      />
                    ) : (
                      <BsPlus
                        size={24}
                        style={{
                          marginBottom: "4px",
                          marginLeft: "-10px",
                          marginRight: "10px",
                        }}
                      />
                    )}

                    <p className="descB">{acc.accordionName}</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p
                      className="descB"
                      style={{ fontSize: "16px", paddingLeft: "18px" }}
                    >
                      {acc.accordionDesc}
                    </p>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
          <div className="col-md-6 col-sm-12" style={{ padding: "20px" }}>
            <img
              src={welcome}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeBanner;

import React from "react";
import "./whyChooseUs.scss";
import community from "../../images/community.png";
import engage from "../../images/engage.png";
import graduation from "../../images/graduation.png";
import award from "../../images/award.png";
import useApiState from "../../store/store";
import * as Tabler from "tabler-icons-react";
function WhyChooseUs() {
  const { allData } = useApiState();
  const matchedItems = allData?.singlePageContent?.data?.filter((item) =>
    item?.keyword.startsWith("facility_")
  );
  return (
    <div className="container section-gap main-container">
      <h1 className="sub_headingB">
        Why Choose <span className="school-name">Nivid School?</span>
      </h1>
      <p className="descB">
        Our students are talented, hard-working and full of good ideas. We
        encourage and empower them to bring their ideas to life. Hands-on
        opportunities are what we're all about.
      </p>
      <hr />
      <div className="row">
        {matchedItems?.map((item, index) => {
          const IconName = `${item.icon
            .split("-")
            ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join("")}`;

          const Icon = Tabler[IconName];

          return (
            <div className="col-lg-3 col-md-3 col-sm-12" key={index}>
              <div className="card">
                {Icon && <Icon />}
                <h5 className="titleB">{item?.title}</h5>
                <p className="desc_text">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.content,
                    }}
                  ></div>
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default WhyChooseUs;

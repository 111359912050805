// import React from "react";
// import { Helmet } from "react-helmet";

// const MetaConfigure = ({ title }) => {
//   return (
//     <div className="application">
//       {" "}
//       <Helmet>
//         {" "}
//         <meta charSet="utf-8" /> <title>{`${title}`}</title>{" "}
//         {/* <link rel="canonical" href="http://mysite.com/example" /> */}{" "}
//         {/* <link rel="apple-touch-icon" href="./images/logo.png" /> */}{" "}
//       </Helmet>{" "}
//     </div>
//   );
// };

// export default MetaConfigure;

import React from "react";
import { Helmet } from "react-helmet";
import useApiState from "../store/store";

export const MetaConfigure = ({ title }) => {
  const { allData } = useApiState();
  const schoolName = allData?.schoolInfo?.data?.[0]?.school_name;
  const schoolImage = allData?.schoolInfo?.data?.[0]?.image_link;

  return (
    <div className="application">
      <Helmet>
        <meta charSet="utf-8" />
        <title
          className="capitalize"
          style={{ textTransform: "capitalize" }}
        >{`${title} | ${schoolName}`}</title>
        <link rel="canonical" href={schoolImage} />
      </Helmet>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import useApiState from "../../store/store";
import TeamCard from "../UI/TeamCard/TeamCard";

const AllTeam = ({ isHomePage }) => {
  const [members, setMembers] = useState();
  const { allData } = useApiState();

  const modifiedMembers = isHomePage ? members?.slice(0, 4) : members;

  useEffect(() => {
    setMembers(allData?.schoolMember?.data);
  }, []);
  return (
    <div className="card_wrapper">
      <div style={{ width: "100%" }} className="row">
        {modifiedMembers &&
          modifiedMembers?.length > 0 &&
          modifiedMembers?.map((item, index) => (
            <div className="col-lg-3 col-md-6  col-sm-12">
              <TeamCard
                key={index}
                image={item?.image_link}
                name={item?.teacherName}
                designation={item?.designation}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default AllTeam;
